import React from 'react';
import PropTypes from 'prop-types';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import { useSelector } from 'react-redux';
import Participation from '../../../../common/models/Participation';
import { default as ProjectSelect } from '../../../../common/selectors/Project';
import { default as RecipientSelect } from '../../../../common/selectors/Recipient';
import ProjectProfilePresentation from './ProjectProfilePresentation';
import { apiZedocOneProject } from '../../../../common/api/zedoc';

const ProjectProfileContainer = ({ participation }) => {
  const { _id: participationId, projectId, recipientId, state } = participation;

  const { ready } = useDDPSubscription(
    apiZedocOneProject.withParams({
      projectId,
    }),
  );

  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const recipient = useSelector(
    RecipientSelect.one().whereIdEquals(recipientId),
  );

  let unsubscribed = false;
  let patientPreferredMethod = '';
  if (!ready || !project || !recipient) {
    return null;
  }

  if (recipient.unsubscribed && recipient.unsubscribed.includes(projectId)) {
    unsubscribed = true;
  }

  if (!unsubscribed) {
    patientPreferredMethod =
      recipient?.preferredNotificationMethodPerProject?.find(
        (method) => method.projectId === projectId,
      )?.value;
    if (!patientPreferredMethod) {
      patientPreferredMethod = recipient?.preferredNotificationMethod;
    }
  }

  return (
    <ProjectProfilePresentation
      recipientId={recipientId}
      projectId={projectId}
      projectName={project.name}
      participationId={participationId}
      participationState={state}
      unsubscribed={unsubscribed}
      patientPreferredMethod={patientPreferredMethod}
    />
  );
};

ProjectProfileContainer.propTypes = {
  participation: PropTypes.instanceOf(Participation).isRequired,
};

export default ProjectProfileContainer;
