import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../common/components/Dropdown';
import Menu from '../../../common/components/Menu';
import Button from '../../Button';
import useForm from '../../../utils/useForm';
import ConfirmAction from '../../dialogs/ConfirmAction';
import { notifySuccess, notifyError } from '../../../utils/notify';

const PatientEngagementNotification = ({
  recipient,
  patientNotificationsDataUpdate,
  sendSignUpLink,
}) => {
  const { t } = useTranslation();
  const phoneInputRef = useRef();
  const { pepUserName } = recipient;
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleOnEdit = () => setIsEditing(true);
  const handleOnCancel = () => setIsEditing(false);
  const phone = recipient.getPhoneNumberMobile();
  const email = recipient.getEmailPersonal();
  const { handleSubmit, renderField } = useForm({
    inputs: {
      phone: {
        ref: phoneInputRef,
        label: t('forms:phone.label'),
        type: 'text',
        defaultValue: phone ? phone.number : '',
        showValueOnly: !isEditing,
        disabled: true,
        schema: {
          type: 'string',
          format: 'phone',
          optional: true,
        },
      },
      email: {
        label: t('forms:email.label'),
        type: 'text',
        defaultValue: email ? email.address : '',
        showValueOnly: !isEditing,
        disabled: isSaving,
        schema: {
          type: 'string',
          optional: true,
        },
      },
    },
    onSubmit: ({ email }) => {
      setIsSaving(true);
      patientNotificationsDataUpdate({ email }).then(() => {
        setIsSaving(false);
        handleOnCancel();
        notifySuccess('Patient Contact Details Updated')();
      });
    },
  });

  const [sendVia, setSendVia] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const handleOnOpenDialog = () => setIsVisible(true);
  const handleOnCloseDialog = () => setIsVisible(false);
  const handleOnOpenSendPEPLinkViaEmail = () => {
    setSendVia('EMAIL');
    handleOnOpenDialog();
  };
  const handleOnOpenSendPEPLinkViaSMS = () => {
    setSendVia('SMS');
    handleOnOpenDialog();
  };
  const handleOnOpenSendPEPLinkViaBoth = () => {
    setSendVia('BOTH');
    handleOnOpenDialog();
  };

  const handleOnSendPEPLink = () => {
    setIsSending(true);
    sendSignUpLink(sendVia.toLowerCase()).then((response) => {
      if (
        response.data.sendSignUpRequest.email ||
        response.data.sendSignUpRequest.sms
      ) {
        notifySuccess(`Link Sent via ${sendVia}`)();
      } else {
        notifyError('Failed to send link')();
      }
      setIsSending(false);
      handleOnCloseDialog();
    });
  };

  return (
    <>
      <div className="stack-6">
        <div className="grid grid-cols-2 gap-4 items-center">
          <div className="stack-0">
            <h2>Notification</h2>
            <h3 className="text-lg font-normal">Patient Contact Details</h3>
          </div>
          <div className="cluster-4 justify-end">
            {isEditing ? (
              <div className="cluster-2">
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  loading={isSaving}
                >
                  {t('save')}
                </Button>
                <Button
                  type="ghost"
                  onClick={handleOnCancel}
                  disabled={isSaving}
                >
                  {t('cancel')}
                </Button>
              </div>
            ) : (
              <Button type="ghost" onClick={handleOnEdit}>
                {t('edit')}
              </Button>
            )}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 items-end">
          {renderField('phone')}
          {renderField('email')}
          <Dropdown
            trigger={['click']}
            disabled={!(phone && phone.number) && !(email && email.address)}
            overlay={
              <Menu>
                {email && email.address && (
                  <Menu.Item
                    key="email"
                    onClick={handleOnOpenSendPEPLinkViaEmail}
                  >
                    Via Email
                  </Menu.Item>
                )}
                {phone && phone.number && (
                  <Menu.Item key="sms" onClick={handleOnOpenSendPEPLinkViaSMS}>
                    Via SMS
                  </Menu.Item>
                )}
                {phone && phone.number && email && email.address && (
                  <Menu.Item
                    key="both"
                    onClick={handleOnOpenSendPEPLinkViaBoth}
                  >
                    Via Both
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <Button disabled={true}>
              {pepUserName ? 'Send PEP Log-In Link' : 'Send PEP Sign-Up Link'}
            </Button>
          </Dropdown>
        </div>
      </div>
      <ConfirmAction
        message={`Are you sure you want to send a sign-up link to the patient via ${sendVia}?`}
        open={isVisible}
        onConfirm={handleOnSendPEPLink}
        onCancel={handleOnCloseDialog}
        loading={isSending}
      />
    </>
  );
};

export default PatientEngagementNotification;
