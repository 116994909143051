import PropTypes from 'prop-types';
import find from 'lodash/find';
import pick from 'lodash/pick';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDDPCall } from '@zedoc/ddp-connector';
import * as presetSdk from '@preset-sdk/embedded';
// eslint-disable-next-line import/no-extraneous-dependencies
import { embedDashboard } from '@superset-ui/embedded-sdk';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { default as ProjectDashboardSelect } from '../../../../common/selectors/ProjectDashboard';
import {
  apiZedocGetPresetGuestToken,
  apiZedocGetSupersetGuestToken,
} from '../../../../common/api/zedoc';
import { notifyError } from '../../../../utils/notify';
import settings from '../../../../common/settings';
import ManageEmailReports from './components/ManageEmailReports';

const { presetEmbedding = {} } = settings.public;
const { supersetDomain, supersetFlag } = presetEmbedding;

const Container = styled.div`
  iframe {
    overflow: hidden;
    background-color: transparent;
    width: 100%;
    height: 1000px;
    border: 0;
  }
`;

const DashboardPreset = ({
  projectId,
  recipientId,
  participationId,
  dashboardId,
}) => {
  const { i18n } = useTranslation();
  const ref = useRef();
  const {
    ddpCall,
    // ddpIsPending,
  } = useDDPCall();

  const projectDashboard = useSelector(
    ProjectDashboardSelect.one().whereIdEquals(dashboardId),
  );

  const dashboardSettings = useMemo(() => {
    if (!projectDashboard || !projectDashboard.settings) {
      return {};
    }
    const finalSettings = pick(projectDashboard.settings, [
      'dashboardId',
      'uiConfig',
      'useOverlays',
      'disableOwnershipFilter',
    ]);
    const { translations } = projectDashboard.settings;
    const matchingTranslation = find(translations, { language: i18n.language });
    if (matchingTranslation) {
      finalSettings.dashboardId = matchingTranslation.dashboardId;
    }
    return finalSettings;
  }, [projectDashboard, i18n.language]);
  const embeddedDashboardId = dashboardSettings.dashboardId;
  useEffect(() => {
    if (!dashboardSettings.dashboardId || !supersetDomain) {
      return () => {};
    }
    const params = {
      projectId,
      useOverlays: !!dashboardSettings.useOverlays,
      embeddedDashboardId: dashboardSettings.dashboardId,
      disableOwnershipFilter: !!dashboardSettings.disableOwnershipFilter,
    };
    if (recipientId) {
      params.recipientId = recipientId;
    }
    if (participationId) {
      params.participationId = participationId;
    }
    const promise = supersetFlag
      ? embedDashboard({
          id: dashboardSettings.dashboardId,
          supersetDomain,
          mountPoint: document.getElementById('superset-container'),
          fetchGuestToken: () =>
            ddpCall(apiZedocGetSupersetGuestToken.withParams(params))
              .then(({ token }) => token)
              .catch((err) => {
                notifyError()(err);
                // NOTE: We return empty string because we don't want unhandled promise
                //       rejection which can potentially crash the app. Returning null
                //       cases crash because preset-sdk expects a string.
                return '';
              }),
          dashboardUiConfig: {
            hideTitle: true,
            hideTab: false,
            hideChartControls: false,
            ...dashboardSettings.uiConfig,
            filters: {
              expanded: false,
              ...dashboardSettings.uiConfig?.filters,
            },
          },
        })
      : presetSdk.embedDashboard({
          id: dashboardSettings.dashboardId,
          supersetDomain,
          mountPoint: ref.current,
          fetchGuestToken: () =>
            ddpCall(apiZedocGetPresetGuestToken.withParams(params))
              .then(({ token }) => token)
              .catch((err) => {
                notifyError()(err);
                // NOTE: We return empty string because we don't want unhandled promise
                //       rejection which can potentially crash the app. Returning null
                //       cases crash because preset-sdk expects a string.
                return '';
              }),
          dashboardUiConfig: {
            hideTitle: true,
            hideTab: false,
            hideChartControls: false,
            ...dashboardSettings.uiConfig,
            filters: {
              expanded: false,
              ...dashboardSettings.uiConfig?.filters,
            },
          },
        });
    return () => {
      // TODO: Check if this cleanly unmounts the dashboard
      //       when we switch between different languages.
      promise.then((dashboard) => {
        dashboard.unmount();
      });
    };
  }, [projectId, recipientId, participationId, ddpCall, dashboardSettings]);
  return supersetFlag ? (
    <div className="stack-2">
      <div className="self-end">
        <ManageEmailReports embeddedDashboardId={embeddedDashboardId} />
      </div>
      <Container id="superset-container" />
    </div>
  ) : (
    <Container ref={ref} />
  );
};

DashboardPreset.propTypes = {
  projectId: PropTypes.string.isRequired,
  recipientId: PropTypes.string,
  participationId: PropTypes.string,
  dashboardId: PropTypes.string.isRequired,
};

DashboardPreset.defaultProps = {
  recipientId: null,
  participationId: null,
};

export default DashboardPreset;
