import compact from 'lodash/compact';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toIntlDateTimeFormat } from '@zedoc/date';
import Text from '../../common/components/base/Text';
import Stack from '../../common/components/primitives/Stack';
import Cluster from '../../common/components/primitives/Cluster';
import Recipient from '../../common/models/Recipient';
import { PATIENT_ACCESS_PATIENT_PII_VARIABLES } from '../../common/permissions';
import usePermission from '../../utils/usePermission';
import settings from '../../common/settings';
import { GENDER_NOT_DESCRIBED, GENDER_X } from '../../common/constants';

const RecipientDetailsList = ({ recipient }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const canSeePII = usePermission([PATIENT_ACCESS_PATIENT_PII_VARIABLES], {
    relativeTo: recipient && recipient.getDomains(),
  });
  const details = useMemo(() => {
    const maskNHI = true;
    const isQH = settings.public?.isQH;
    const items = compact([
      // not sure how to properyly "hide" / "mask" the identifier
      // but will just leave it as "*******"
      // TECH-2473
      recipient.getIdentifier() && {
        title: t('forms:identifier.label'),
        description: maskNHI ? '*******' : recipient.getIdentifier(),
      },
      recipient.getGender() && {
        title: isQH ? t('forms:gender.label_X') : t('forms:gender.label'),
        description: recipient.getGender(),
      },
      recipient.formatEthnicity() && {
        title: t('forms:ethnicity.label'),
        description: recipient.formatEthnicity(),
      },
      recipient.birthDate && {
        title: t('forms:dateOfBirth.label'),
        description: toIntlDateTimeFormat(
          language,
          new Date(recipient.birthDate),
          {
            dateStyle: 'short',
          },
        ),
      },
      recipient.getNationalId() && {
        title: t('forms:identifier.label'),
        description: maskNHI ? '*******' : recipient.getNationalId(),
      },
    ]);

    if (recipient.hasPhoneNumbers()) {
      recipient.getPhoneNumbers().map(({ number, type }) =>
        items.push({
          title: `${t(`forms:phone.types.${type}`)}`,
          description: <a href={`tel:${number}`}>{number}</a>,
        }),
      );
    }

    if (canSeePII && recipient.hasEmailAddresses()) {
      recipient.getEmailAddresses().map(({ address, type }) =>
        items.push({
          title: t(`forms:email.types.${type}`),
          description: <a href={`mailto:${address}`}>{address}</a>,
        }),
      );
    }

    return items;
  }, [recipient, canSeePII, language, t]);

  return (
    <Cluster>
      {details.map(({ title, description }, i) => (
        <React.Fragment
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        >
          {i > 0 && <div>&#xb7;</div>}
          <Stack space={0}>
            <Text.Paragraph importance="high">{title}</Text.Paragraph>
            <Text.Paragraph>{description}</Text.Paragraph>
          </Stack>
        </React.Fragment>
      ))}
    </Cluster>
  );
};

RecipientDetailsList.propTypes = {
  recipient: PropTypes.instanceOf(Recipient),
};

RecipientDetailsList.defaultProps = {
  recipient: new Recipient({}),
};

export default RecipientDetailsList;
