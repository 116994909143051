import sanitizeHtml from 'sanitize-html';
import isArray from 'lodash/isArray';
import trim from 'lodash/trim';
import { escapeRegExp } from '@zedoc/text';

/**
 * @param {string | undefined} type
 * @returns {string | undefined}
 */
const getClassNameByType = (type) => {
  switch (type) {
    case 'danger':
      return 'bg-danger';
    case 'success':
      return 'bg-success text-on-success';
    case 'warning':
      return 'bg-warning text-on-warning';
    case 'info':
      return 'bg-info text-on-info';
    case 'neutral':
      return 'bg-neutral text-on-neutral';
    default:
      return undefined;
  }
};

/**
 * @param {string | undefined} text
 * @param {string | string[] | undefined} highlight
 * @param {string | undefined} type
 * @returns {string}
 */
const renderSanitizedHtml = (text, highlight, type) => {
  if (!text) {
    return '';
  }
  if (!highlight) {
    return text;
  }
  if (!isArray(highlight)) {
    return renderSanitizedHtml(
      text,
      highlight.split(/\s+/).filter(Boolean),
      type,
    );
  }
  const regexes = highlight
    .filter(trim)
    .map((chunk) => new RegExp(`\\b(${escapeRegExp(chunk)})\\b`, 'gi'));

  let highlightedText = text;

  regexes.forEach((regex) => {
    highlightedText = highlightedText.replace(regex, (match) => {
      return `<mark class="${getClassNameByType(type)}">${match}</mark>`;
    });
  });

  return sanitizeHtml(highlightedText, {
    allowedTags: ['mark'],
    allowedAttributes: { mark: ['class'] },
  });
};

export default renderSanitizedHtml;
