const generateOptions = (n, startFrom = 0) =>
  Array.from({ length: n }, (_, i) => ({
    label: (i + startFrom).toString(),
    value: (i + startFrom).toString(),
  }));

export const INTERVAL_YEAR = 'year';
export const INTERVAL_MONTH = 'month';
export const INTERVAL_WEEK = 'week';
export const INTERVAL_DAY = 'day';
export const INTERVAL_HOUR = 'hour';
export const INTERVAL_MINUTE = 'minute';

export const minutesOptions = generateOptions(60);
export const hoursOptions = generateOptions(24);
export const daysOfWeekOptions = [
  {
    label: 'Sunday',
    value: '0',
  },
  {
    label: 'Monday',
    value: '1',
  },
  {
    label: 'Tuesday',
    value: '2',
  },
  {
    label: 'Wednesday',
    value: '3',
  },
  {
    label: 'Thursday',
    value: '4',
  },
  {
    label: 'Friday',
    value: '5',
  },
  {
    label: 'Saturday',
    value: '6',
  },
];
export const daysOfMonthOptions = generateOptions(31, 1);
export const monthsOptions = [
  {
    label: 'January',
    value: '1',
  },
  {
    label: 'February',
    value: '2',
  },
  {
    label: 'March',
    value: '3',
  },
  {
    label: 'April',
    value: '4',
  },
  {
    label: 'May',
    value: '5',
  },
  {
    label: 'June',
    value: '6',
  },
  {
    label: 'July',
    value: '7',
  },
  {
    label: 'August',
    value: '8',
  },
  {
    label: 'September',
    value: '9',
  },
  {
    label: 'October',
    value: '10',
  },
  {
    label: 'November',
    value: '11',
  },
  {
    label: 'December',
    value: '12',
  },
];
export const intervalOptions = [
  {
    label: 'Year',
    value: INTERVAL_YEAR,
  },
  {
    label: 'Month',
    value: INTERVAL_MONTH,
  },
  {
    label: 'Week',
    value: INTERVAL_WEEK,
  },
  {
    label: 'Day',
    value: INTERVAL_DAY,
  },
  {
    label: 'Hour',
    value: INTERVAL_HOUR,
  },
  {
    label: 'Minute',
    value: INTERVAL_MINUTE,
  },
];

// Compress an array of number strings into a range string.
export function compressArray(arr) {
  // Convert strings to numbers and sort them
  const nums = arr.map(Number).sort((a, b) => a - b);
  let ranges = [];
  let start = nums[0];
  let end = nums[0];

  for (let i = 1; i < nums.length; i++) {
    const current = nums[i];
    if (current === end + 1) {
      // Extend the range
      end = current;
    } else {
      // End the current range
      ranges.push(start === end ? `${start}` : `${start}-${end}`);
      start = current;
      end = current;
    }
  }
  // Add the final range
  ranges.push(start === end ? `${start}` : `${start}-${end}`);
  return ranges.join(',');
}

//  Decompress a range string (e.g., "1-4,7,9-10") into an array of individual numbers (as strings).
export function decompressRange(str) {
  if (!str) return [];
  const parts = str.split(',');
  let result = [];
  for (const part of parts) {
    if (part.includes('-')) {
      const [start, end] = part.split('-').map(Number);
      for (let i = start; i <= end; i++) {
        result.push(i.toString());
      }
    } else {
      result.push(part);
    }
  }
  // Ensure sorted order
  return result
    .map(Number)
    .sort((a, b) => a - b)
    .map((n) => n.toString());
}

/**
 * Converts scheduling parameters to a cron expression with compressed ranges.
 * The cron format is: "minutes hours day-of-month month day-of-week"
 *
 * @param {string} interval - E.g., "year" (not used in the cron string).
 * @param {string[]} minutes
 * @param {string[]} hours
 * @param {string[]} daysOfMonth
 * @param {string[]} months
 * @param {string[]} daysOfWeek
 * @returns {string} The cron expression.
 */
export function paramsToCron({
  interval,
  minutes = [],
  hours = [],
  daysOfMonth = [],
  months = [],
  daysOfWeek = [],
}) {
  // Adjust fields based on interval
  switch (interval) {
    case 'year':
      // Use all fields as provided.
      break;
    case 'month':
      // For a monthly schedule, the month field is not needed.
      months = [];
      break;
    case 'week':
      // For a weekly schedule, ignore months and day-of-month.
      months = [];
      daysOfMonth = [];
      break;
    case 'day':
      // For a daily schedule, ignore months, day-of-month, and day-of-week.
      months = [];
      daysOfMonth = [];
      daysOfWeek = [];
      break;
    case 'hour':
      // For an hourly schedule, ignore months, day-of-month, day-of-week, and hours.
      months = [];
      daysOfMonth = [];
      daysOfWeek = [];
      hours = [];
      break;
    case 'minute':
      // For a per-minute schedule, only minutes are relevant.
      months = [];
      daysOfMonth = [];
      daysOfWeek = [];
      hours = [];
      minutes = [];
      break;
    default:
      break;
  }

  // Use compressed ranges if the array is non-empty; otherwise, use wildcard "*"
  const minField = minutes.length ? compressArray(minutes) : '*';
  const hourField = hours.length ? compressArray(hours) : '*';
  const domField = daysOfMonth.length ? compressArray(daysOfMonth) : '*';
  const monthField = months.length ? compressArray(months) : '*';
  const dowField = daysOfWeek.length ? compressArray(daysOfWeek) : '*';

  return `${minField} ${hourField} ${domField} ${monthField} ${dowField}`;
}

export function cronToParams(cronExpression) {
  const parts = cronExpression.split(' ');
  if (parts.length !== 5) {
    throw new Error(
      'Invalid cron expression. Expected 5 fields: minutes, hours, day-of-month, month, day-of-week.',
    );
  }

  let [minField, hourField, domField, monthField, dowField] = parts;

  const minutes = minField === '*' ? [] : decompressRange(minField);
  const hours = hourField === '*' ? [] : decompressRange(hourField);
  const daysOfMonth = domField === '*' ? [] : decompressRange(domField);
  const months = monthField === '*' ? [] : decompressRange(monthField);
  const daysOfWeek = dowField === '*' ? [] : decompressRange(dowField);

  // Determine interval based on empty fields:
  // - "year": all fields provided (months non-empty)
  // - "month": months empty but daysOfMonth provided
  // - "week": months and daysOfMonth empty but daysOfWeek provided
  // - "day": months, daysOfMonth, and daysOfWeek empty but hours provided
  // - "hour": months, daysOfMonth, daysOfWeek, and hours empty but minutes provided
  // - "minute": if all fields are empty (should rarely occur)
  let interval;
  if (months.length > 0) {
    interval = 'year';
  } else if (daysOfMonth.length > 0) {
    interval = 'month';
  } else if (daysOfWeek.length > 0) {
    interval = 'week';
  } else if (hours.length > 0) {
    interval = 'day';
  } else if (minutes.length > 0) {
    interval = 'hour';
  } else {
    interval = 'minute';
  }

  return {
    interval,
    minutes,
    hours,
    daysOfMonth,
    months,
    daysOfWeek,
  };
}
