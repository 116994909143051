import {
  FILTER_TYPE__PROPERTY,
  // FILTER_TYPE__VARIABLE,
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__SEARCH_TERMS,
  FILTER_CONDITION__INCLUDE,
  FILTER_CONDITION__EXCLUDE,
  // FILTER_CONDITION__EXISTS,
  // FILTER_CONDITION__DOES_NOT_EXIST,
  FILTER_CONDITION__EQUALS,
  FILTER_CONDITION__NOT_EQUAL,
  FILTER_CONDITION__MINIMUM,
  FILTER_CONDITION__MAXIMUM,
  FILTER_CONDITION__EXCLUSIVE_MINIMUM,
  FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
  FILTER_CONDITION__EMPTY,
  FILTER_CONDITION__NON_EMPTY,
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_NOT_EQUAL,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
  FILTER_CONDITION__EXISTS,
  FILTER_CONDITION__DOES_NOT_EXIST,
} from '../../../../common/constants';

export const getPatientVariableFilter = (name, id) => {
  return {
    name,
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: `patientVariables.${id}`,
    },
    meta: {
      conditions: [FILTER_CONDITION__INCLUDE, FILTER_CONDITION__EXCLUDE],
    },
  };
};

export const getHhsNameFilter = (id) => {
  return {
    name: 'HHS Name',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: `patientVariables.${id}`,
    },
    meta: {
      conditions: [FILTER_CONDITION__INCLUDE, FILTER_CONDITION__EXCLUDE],
    },
  };
};

export const getHhsCodeFilter = (id) => {
  return {
    name: 'HHS Code',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: `patientVariables.${id}`,
    },
    meta: {
      conditions: [FILTER_CONDITION__INCLUDE, FILTER_CONDITION__EXCLUDE],
    },
  };
};

const defaultFilters = [
  {
    name: 'Name',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: 'name',
    },
    meta: {
      conditions: [FILTER_CONDITION__INCLUDE, FILTER_CONDITION__EXCLUDE],
    },
  },
  {
    name: 'Milestone',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: 'milestoneId',
    },
    meta: {
      conditions: [
        FILTER_CONDITION__INCLUDE,
        FILTER_CONDITION__EXCLUDE,
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ],
    },
  },
  {
    name: 'Reference No',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__TEXT,
    settings: {
      id: 'referenceNoTerms',
    },
    meta: {
      conditions: [FILTER_CONDITION__TEXT, FILTER_CONDITION__SEARCH_TERMS],
    },
  },
  {
    name: 'Trigger type',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: 'triggerType',
    },
    meta: {
      conditions: [FILTER_CONDITION__INCLUDE, FILTER_CONDITION__EXCLUDE],
    },
  },
  {
    name: 'State',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: 'state',
    },
    meta: {
      conditions: [FILTER_CONDITION__INCLUDE, FILTER_CONDITION__EXCLUDE],
    },
  },
  {
    name: 'Assignee',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: 'assigneeId',
    },
    meta: {
      conditions: [FILTER_CONDITION__INCLUDE, FILTER_CONDITION__EXCLUDE],
    },
  },
  {
    name: 'Priority',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: 'priority',
    },
    meta: {
      conditions: [FILTER_CONDITION__INCLUDE, FILTER_CONDITION__EXCLUDE],
    },
  },
  {
    name: 'Date',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__DATE_EQUALS,
    settings: {
      id: 'createdAt',
    },
    meta: {
      conditions: [
        FILTER_CONDITION__DATE_EQUALS,
        FILTER_CONDITION__DATE_NOT_EQUAL,
        FILTER_CONDITION__DATE_SAME_OR_AFTER,
        FILTER_CONDITION__DATE_SAME_OR_BEFORE,
        FILTER_CONDITION__DATE_AFTER,
        FILTER_CONDITION__DATE_BEFORE,
      ],
    },
  },
  {
    name: 'Notes',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__NON_EMPTY,
    settings: {
      id: 'notes',
    },
    state: {},
    meta: {
      conditions: [FILTER_CONDITION__NON_EMPTY, FILTER_CONDITION__EMPTY],
    },
  },
  {
    name: 'Completed actions',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__MINIMUM,
    state: {
      threshold: 1,
    },
    settings: {
      id: 'nCompleted',
      valueType: 'number',
    },
    meta: {
      conditions: [
        FILTER_CONDITION__MINIMUM,
        FILTER_CONDITION__MAXIMUM,
        FILTER_CONDITION__EXCLUSIVE_MINIMUM,
        FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
        FILTER_CONDITION__EQUALS,
        FILTER_CONDITION__NOT_EQUAL,
      ],
    },
  },
];

export default defaultFilters;
