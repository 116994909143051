import map from 'lodash/map';
import { UserOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Recipient from '../../../common/models/Recipient';
import Participation from '../../../common/models/Participation';
import { PATIENT_ACCESS_PATIENT_PII_VARIABLES } from '../../../common/permissions';
import Sidebar from '../../../common/components/primitives/Sidebar';
import Stack from '../../../common/components/primitives/Stack';
import Cluster from '../../../common/components/primitives/Cluster';
import Text from '../../../common/components/base/Text';
import Avatar from '../../../common/components/Avatar';
import Divider from '../../../common/components/Divider';
import usePermission from '../../../utils/usePermission';
import RecipientDetailsList from '../RecipientDetailsList';
import RecipientVariablesList from '../RecipientVariablesList';

const PatientDetails = ({
  participations,
  recipient,
  actions,
  onEdit,
  canEdit,
}) => {
  const canSeePII = usePermission([PATIENT_ACCESS_PATIENT_PII_VARIABLES], {
    relativeTo: recipient && recipient.getDomains(),
  });
  const { t } = useTranslation();
  const userIntegrationId = 'kMi8nxZMJxpFc5cTY'; // FIXME: Get this identifier from somewhere.
  const addedVia = useMemo(
    () =>
      recipient &&
      (recipient.createdBy === userIntegrationId ||
        recipient.createdBy.includes(userIntegrationId))
        ? t('addedViaIntegration')
        : t('addedManually'),
    [recipient, t],
  );

  return (
    <Stack>
      <Sidebar sidebar={<Cluster>{actions.map((action) => action)}</Cluster>}>
        <Cluster>
          <Avatar size="large">
            <UserOutlined />
          </Avatar>
          <Stack space={0}>
            <Text.Paragraph size="large" importance="high">
              {canSeePII ? recipient.getFullName() : recipient.getTruncatedId()}
            </Text.Paragraph>
            <Text.Paragraph size="small">{addedVia}</Text.Paragraph>
          </Stack>
        </Cluster>
      </Sidebar>
      <Stack>
        <RecipientDetailsList recipient={recipient} />
        <Stack>
          <Divider />
          {map(participations, (participation) => (
            <RecipientVariablesList
              key={participation._id}
              recipient={recipient}
              participation={participation}
              onEdit={onEdit}
              canEdit={canEdit}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

PatientDetails.propTypes = {
  recipient: PropTypes.instanceOf(Recipient),
  participations: PropTypes.arrayOf(PropTypes.instanceOf(Participation)),
  actions: PropTypes.arrayOf(PropTypes.node),
  onEdit: PropTypes.func,
  canEdit: PropTypes.bool,
};

PatientDetails.defaultProps = {
  recipient: new Recipient({}),
  participations: [],
  actions: [],
  onEdit: () => {},
  canEdit: false,
};

export default PatientDetails;
