import './styles.css';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line consistent-default-export-name/default-import-match-filename
import CreatableReactSelect from 'react-select/creatable';
import Group from './Group';
import Input from './Input';
import GroupHeading from './GroupHeading';
import SelectContainer from './SelectContainer';
import Option from './Option';
import MultiValue from './MultiValue';
import ValueContainer from './ValueContainer';
import { getValue } from './utils';

const IndicatorSeparator = () => null;

const Select = ({
  'data-testid': testId,
  type,
  options,
  value,
  onChange,
  onAfterChange,
  inputValue,
  onInputChange,
  placeholder,
  isMulti,
  isClearable,
  isCreatable,
  isDisabled,
  hasError,
}) => {
  const handleOnChange = useCallback(
    (res) => {
      const val = res && (isMulti ? res.map((o) => o.value) : res.value);

      onChange(val);
      onAfterChange(val);
    },
    [isMulti, onChange, onAfterChange],
  );

  const memoValue = useMemo(
    () => getValue(options, value, (opt) => opt.value, isMulti, isCreatable),
    [options, value, isMulti, isCreatable],
  );

  const props = useMemo(
    () => ({
      testId,
      className: 'z-react-select-container',
      classNamePrefix: 'z-react-select',
      components: {
        SelectContainer,
        Input,
        Group,
        GroupHeading,
        Option,
        IndicatorSeparator,
        MultiValue,
        ValueContainer,
      },
      options,
      value: memoValue,
      placeholder,
      isDisabled,
      isMulti,
      isClearable,
      closeMenuOnSelect: !isMulti,
      onChange: handleOnChange,
      menuPosition: 'fixed',
      menuPortalTarget: document.body,
      inputValue,
      onInputChange,
      hideSelectedOptions: false,
      styles: {
        control: (baseStyles) => ({
          ...baseStyles,
          ...(hasError
            ? {
                border: '1px solid var(--color-danger-800) !important',
              }
            : {}),
        }),
      },
      // NOTE: Useful prop for DOM debugging to keep the menu open
      // menuIsOpen
    }),
    [
      testId,
      options,
      memoValue,
      placeholder,
      isDisabled,
      isMulti,
      isClearable,
      handleOnChange,
      inputValue,
      onInputChange,
      hasError,
    ],
  );

  if (isCreatable) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <CreatableReactSelect {...props} />
    );
  }

  // TODO: Improve disabled styling
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ReactSelect {...props} />
  );
};

Select.propTypes = {
  'data-testid': PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string,
        }),
      ),
    }),
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  onAfterChange: PropTypes.func,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  isCreatable: PropTypes.bool,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  hasError: PropTypes.bool,
};

Select.defaultProps = {
  'data-testid': 'select',
  type: 'secondary',
  options: [],
  value: null,
  onChange: () => {},
  onAfterChange: () => {},
  placeholder: null,
  isDisabled: false,
  isMulti: false,
  isClearable: false,
  isCreatable: false,
  inputValue: undefined,
  onInputChange: null,
  hasError: false,
};

export default Select;
