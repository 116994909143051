export const CONTACT_TYPE_PERSONAL = 'Personal';
export const CONTACT_TYPE_HOME = 'Home';
export const CONTACT_TYPE_WORK = 'Work';
export const CONTACT_TYPE_MOBILE = 'Mobile';
export const CONTACT_TYPE_NOK_MOBILE = 'NOK Mobile';
export const CONTACT_TYPE_TEMPORARY = 'Temporary';
export const CONTACT_TYPE_OLD = 'Old';
export const CONTACT_TYPE_CARER = 'Carer';
export const CONTACT_TYPE_PARENT = 'Parent';

export const PHONE_TYPES = /** @type {const} */ ([
  CONTACT_TYPE_PERSONAL,
  CONTACT_TYPE_HOME,
  CONTACT_TYPE_WORK,
  CONTACT_TYPE_MOBILE,
  CONTACT_TYPE_NOK_MOBILE,
  CONTACT_TYPE_TEMPORARY,
  CONTACT_TYPE_OLD,
]);

export const EMAIL_TYPES = /** @type {const} */ ([
  CONTACT_TYPE_PERSONAL,
  CONTACT_TYPE_HOME,
  CONTACT_TYPE_WORK,
  CONTACT_TYPE_MOBILE,
  CONTACT_TYPE_TEMPORARY,
  CONTACT_TYPE_OLD,
  CONTACT_TYPE_CARER,
  CONTACT_TYPE_PARENT,
]);

export const PHONE_TYPE_OPTIONS = [
  {
    value: CONTACT_TYPE_HOME,
    label: 'forms:phone.options.home',
  },
  {
    value: CONTACT_TYPE_WORK,
    label: 'forms:phone.options.work',
  },
  {
    value: CONTACT_TYPE_MOBILE,
    label: 'forms:phone.options.mobile',
  },
  {
    value: CONTACT_TYPE_NOK_MOBILE,
    label: 'forms:phone.options.nokMobile',
  },
];

export const EMAIL_TYPE_OPTIONS = [
  {
    value: CONTACT_TYPE_PERSONAL,
    label: 'forms:email.options.personal',
  },
  {
    value: CONTACT_TYPE_WORK,
    label: 'forms:email.options.work',
  },
  {
    value: CONTACT_TYPE_CARER,
    label: 'forms:email.options.carer',
  },
  {
    value: CONTACT_TYPE_PARENT,
    label: 'forms:email.options.parent',
  },
];

export const GENDER_MALE = 'Male';
export const GENDER_FEMALE = 'Female';
export const GENDER_DIVERSE = 'Diverse';
export const GENDER_UNKNOWN = 'Unknown';
export const GENDER_X = 'X';
export const GENDER_NOT_DESCRIBED = 'Not stated/inadequately described';

export const PATIENT_STATUS_ACTIVE = 'Active';
export const PATIENT_STATUS_INACTIVE = 'Inactive';
export const PATIENT_STATUS_DECEASED = 'Deceased';
export const PATIENT_STATUS_DISCHARGED = 'Discharged';

export const PATIENT_STATUS_OPTIONS = [
  PATIENT_STATUS_ACTIVE,
  PATIENT_STATUS_INACTIVE,
  PATIENT_STATUS_DECEASED,
];
