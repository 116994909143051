import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import Participation from '../../../common/models/Participation';
import Recipient from '../../../common/models/Recipient';
import PatientEngagementAccountManagement from './PatientEngagementAccountManagement';
import PatientEngagementNotification from './PatientEngagementNotification';
import PatientEngagementNotificationPreferences from './PatientEngagementNotificationPreferences';
import { PATIENT_VIEW_PATIENT_ENGAGEMENT } from '../../../common/permissions';
import usePermission from '../../../utils/usePermission';
import { callMethod } from '../../../common/utilsClient/ddp/actions';
import {
  apiZedocSubscribeRecipientAllProjects,
  apiZedocUnsubscribeRecipientAllProjects,
  apiZedocNotificationMethodPreferenceRecipientPerProjects,
  apiZedocUpdateRecipientNotificationsInfo,
  apiZedocSendSignUpLink,
  apiZedocSendResetPepLink,
  apiZedocDeletePEPUser,
  apiZedocUpdateRecipientStatus,
} from '../../../common/api/zedoc';

const PatientEngagement = ({ participations, recipient }) => {
  const dispatch = useDispatch();

  const isAdmin = usePermission([PATIENT_VIEW_PATIENT_ENGAGEMENT], {
    relativeTo: recipient.getDomains(),
  });

  const unsubscribe_all = () => {
    if (!isAdmin) return;
    return dispatch(
      callMethod(apiZedocUnsubscribeRecipientAllProjects, {
        recipientId: recipient._id,
      }),
    );
  };
  const subscribe_all = () => {
    if (!isAdmin) return;
    return dispatch(
      callMethod(apiZedocSubscribeRecipientAllProjects, {
        recipientId: recipient._id,
      }),
    );
  };
  const patientNotificationsDataUpdate = ({ email }) => {
    if (!isAdmin) return;
    return dispatch(
      callMethod(apiZedocUpdateRecipientNotificationsInfo, {
        recipientId: recipient._id,
        email,
      }),
    );
  };

  const notificationMethodPreference = (notificationMethod, projectId) => {
    if (!isAdmin) return;
    return dispatch(
      callMethod(apiZedocNotificationMethodPreferenceRecipientPerProjects, {
        recipientId: recipient._id,
        projectId: projectId,
        notificationMethod,
      }),
    );
  };

  const sendSignUpLink = (type) => {
    if (!isAdmin) return;
    return dispatch(
      callMethod(apiZedocSendSignUpLink, {
        recipientId: recipient._id,
        type,
      }),
    );
  };

  const sendResetPepLink = () => {
    if (!isAdmin) return;
    return dispatch(
      callMethod(apiZedocSendResetPepLink, {
        recipientId: recipient._id,
      }),
    );
  };

  const deletePEPUser = () => {
    if (!isAdmin) return;
    return dispatch(
      callMethod(apiZedocDeletePEPUser, {
        recipientId: recipient._id,
      }),
    );
  };

  const updateRecipientStatus = (status) => {
    if (!isAdmin) return;
    return dispatch(
      callMethod(apiZedocUpdateRecipientStatus, {
        recipientId: recipient._id,
        status,
      }),
    );
  };

  return (
    <div className="stack-6">
      <PatientEngagementAccountManagement
        recipient={recipient}
        sendResetPepLink={sendResetPepLink}
        deletePEPUser={deletePEPUser}
      />
      <hr />
      <PatientEngagementNotification
        recipient={recipient}
        patientNotificationsDataUpdate={patientNotificationsDataUpdate}
        sendSignUpLink={sendSignUpLink}
      />
      <hr />
      <PatientEngagementNotificationPreferences
        participations={uniqBy(participations, 'projectId')}
        recipient={recipient}
        notificationMethodPreference={notificationMethodPreference}
        unsubscribe_all={unsubscribe_all}
        subscribe_all={subscribe_all}
        updateRecipientStatus={updateRecipientStatus}
      />
    </div>
  );
};

PatientEngagement.propTypes = {
  participations: PropTypes.arrayOf(PropTypes.instanceOf(Participation)),
  recipient: PropTypes.instanceOf(Recipient),
};

PatientEngagement.defaultProps = {
  participations: [],
  recipient: {},
};

export default PatientEngagement;
