import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import { useSelector } from 'react-redux';
import Participation from '../../../common/models/Participation';
import {
  PATIENT_STATUS_DECEASED,
  PATIENT_STATUS_ACTIVE,
  PATIENT_STATUS_INACTIVE,
} from '../../../common/constants';
import ConfirmAction from '../../dialogs/ConfirmAction';
import FormFieldWrapper from '../../forms/FormFieldWrapper';
import Select from '../../Select';
import { notifySuccess } from '../../../utils/notify';

const PatientStatus = ({ recipient, updateRecipientStatus }) => {
  const currentStatus = useMemo(() => {
    let status = recipient && recipient.status;
    return status || PATIENT_STATUS_ACTIVE;
  }, [recipient]);

  const [selectedStatus, setSelectedStatus] = useState('');
  const Statuses = [
    {
      label: PATIENT_STATUS_ACTIVE,
      value: PATIENT_STATUS_ACTIVE,
    },
    {
      label: PATIENT_STATUS_INACTIVE,
      value: PATIENT_STATUS_INACTIVE,
    },
    {
      label: PATIENT_STATUS_DECEASED,
      value: PATIENT_STATUS_DECEASED,
    },
  ];
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const handleOnOpenDialog = () => setIsDialogVisible(true);
  const handleOnCloseDialog = () => setIsDialogVisible(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOnChangeMethod = (status) => {
    setSelectedStatus(status);
    handleOnOpenDialog();
  };
  const handleOnDialogConfirm = () => {
    setIsLoading(true);
    updateRecipientStatus(selectedStatus);
    handleOnCloseDialog();
    notifySuccess(
      `Status for patient updated to ${selectedStatus.toUpperCase()}`,
    )();
    setIsLoading(false);
  };

  return (
    <>
      <FormFieldWrapper label="Patient Status">
        <Select
          options={Statuses}
          value={currentStatus}
          onChange={handleOnChangeMethod}
        />
      </FormFieldWrapper>
      <ConfirmAction
        message={`Are you sure you want to change the patient status to ${selectedStatus.toUpperCase()}?`}
        open={isDialogVisible}
        onConfirm={handleOnDialogConfirm}
        onCancel={handleOnCloseDialog}
        loading={isLoading}
      />
    </>
  );
};

export default PatientStatus;
