import React, { useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import useForm from '../../../utils/useForm';
import TogglePasswordVisibilityButton from '../../forms/TogglePasswordVisibilityButton';
import ConfirmAction from '../../dialogs/ConfirmAction';
import { notifySuccess } from '../../../utils/notify';

const PatientEngagementAccountManagement = ({
  recipient: { pepUserName, pepEmail },
  sendResetPepLink,
  deletePEPUser,
}) => {
  const { t } = useTranslation();
  const usernameInputRef = useRef();
  const emailInputRef = useRef();

  const { renderField } = useForm({
    inputs: {
      username: {
        ref: usernameInputRef,
        label: t('forms:username.label'),
        type: 'password',
        defaultValue: pepUserName,
        readOnly: true,
        suffix: <TogglePasswordVisibilityButton inputRef={usernameInputRef} />,
        schema: {
          type: 'string',
          optional: true,
        },
      },
      email: {
        ref: emailInputRef,
        label: t('forms:email.label'),
        type: 'password',
        defaultValue: pepEmail,
        readOnly: true,
        suffix: <TogglePasswordVisibilityButton inputRef={emailInputRef} />,
        schema: {
          type: 'string',
          optional: true,
        },
      },
    },
  });

  const [isSending, setIsSending] = useState(false);
  const [isResetPassword, setResetPassword] = useState(true);
  const message = useMemo(() => {
    if (isResetPassword) {
      return 'Are you sure you want to send password reset email?';
    }
    return 'Are you sure you want to delete this user?';
  }, [isResetPassword]);
  const [
    isConfirmSendPasswordResetEmailVisible,
    setIsConfirmSendPasswordResetEmailVisible,
  ] = useState(false);
  const handleOnOpenSendPasswordResetEmailDialog = () => {
    setResetPassword(true);
    setIsConfirmSendPasswordResetEmailVisible(true);
  };
  const handleOnOpenDeleteUserDialog = () => {
    setResetPassword(false);
    setIsConfirmSendPasswordResetEmailVisible(true);
  };
  const handleOnCloseSendPasswordResetEmailDialog = () =>
    setIsConfirmSendPasswordResetEmailVisible(false);
  const handleOnSendPasswordResetEmail = () => {
    setIsSending(true);
    if (isResetPassword) {
      sendResetPepLink();
      setIsSending(false);
      handleOnCloseSendPasswordResetEmailDialog();
      notifySuccess('Password Reset Email Sent')();
    } else {
      deletePEPUser().then(() => {
        setIsSending(false);
        handleOnCloseSendPasswordResetEmailDialog();
        notifySuccess('User Deleted')();
      });
    }
  };

  return (
    <div className="stack-6">
      <h2>User Account Management</h2>
      <div className="grid grid-cols-4 gap-4 items-end">
        {renderField('username')}
        {renderField('email')}
        <Button onClick={handleOnOpenDeleteUserDialog} disabled={!pepUserName}>
          Delete User
        </Button>
        <Button
          onClick={handleOnOpenSendPasswordResetEmailDialog}
          disabled={!pepUserName}
        >
          Send Password Reset Email
        </Button>
        <ConfirmAction
          message={message}
          open={isConfirmSendPasswordResetEmailVisible}
          onConfirm={handleOnSendPasswordResetEmail}
          onCancel={handleOnCloseSendPasswordResetEmailDialog}
          loading={isSending}
        />
      </div>
    </div>
  );
};

export default PatientEngagementAccountManagement;
