import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import { useSelector } from 'react-redux';
import Participation from '../../../common/models/Participation';
import { default as ProjectSelect } from '../../../common/selectors/Project';
import { default as RecipientSelect } from '../../../common/selectors/Recipient';
import { apiZedocOneProject } from '../../../common/api/zedoc';
import ConfirmAction from '../../dialogs/ConfirmAction';
import FormFieldWrapper from '../../forms/FormFieldWrapper';
import Select from '../../Select';
import { notifySuccess } from '../../../utils/notify';

const NotificationPreferencesProject = ({
  participation,
  enableNotifications,
  recipient,
  notificationMethodPreference,
}) => {
  const { projectId, recipientId } = participation;

  const { ready } = useDDPSubscription(
    apiZedocOneProject.withParams({
      projectId,
    }),
  );

  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));

  const phone = useMemo(() => recipient?.getPhoneNumberMobile(), [recipient]);
  const email = useMemo(() => recipient?.getEmailPersonal(), [recipient]);

  const currentMethod = useMemo(() => {
    let method =
      recipient &&
      recipient?.preferredNotificationMethodPerProject?.find(
        (method) => method.projectId === projectId,
      )?.value;
    if (!method) {
      method = recipient && recipient?.preferredNotificationMethod;
    }
    return method || 'default';
  }, [recipient, projectId]);

  const [selectedMethod, setSelectedMethod] = useState('');
  const notificationPreferencesOptions = useMemo(
    () =>
      [
        {
          label: 'Email',
          value: 'email',
        },
        {
          label: 'SMS',
          value: 'sms',
        },
        {
          label: 'Both',
          value: 'both',
        },
        {
          label: 'Default',
          value: 'default',
        },
      ].filter((option) => {
        if (option.value === 'email' && !email) {
          return false;
        }
        if (option.value === 'sms' && !phone) {
          return false;
        }
        if (option.value === 'both' && (!phone || !email)) {
          return false;
        }
        return true;
      }),
    [email, phone],
  );

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const handleOnOpenDialog = () => setIsDialogVisible(true);
  const handleOnCloseDialog = () => setIsDialogVisible(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOnChangeMethod = (method) => {
    setSelectedMethod(method);
    handleOnOpenDialog();
  };
  const handleOnDialogConfirm = () => {
    setIsLoading(true);
    notificationMethodPreference(selectedMethod, projectId);
    handleOnCloseDialog();
    notifySuccess(
      `Notification method for ${project.getName()} updated to ${selectedMethod.toUpperCase()}`,
    )();
    setIsLoading(false);
  };

  return !ready || !project || !recipient ? null : (
    <>
      <FormFieldWrapper
        label={project.getName()}
        disabled={!enableNotifications}
      >
        <Select
          options={notificationPreferencesOptions}
          value={currentMethod}
          onChange={handleOnChangeMethod}
          isDisabled={!enableNotifications}
        />
      </FormFieldWrapper>
      <ConfirmAction
        message={`Are you sure you want to change the notification preference to ${selectedMethod.toUpperCase()}?`}
        open={isDialogVisible}
        onConfirm={handleOnDialogConfirm}
        onCancel={handleOnCloseDialog}
        loading={isLoading}
      />
    </>
  );
};

NotificationPreferencesProject.propTypes = {
  participation: PropTypes.instanceOf(Participation).isRequired,
  enableNotifications: PropTypes.bool,
};

NotificationPreferencesProject.defaultProps = {
  enableNotifications: false,
};

export default NotificationPreferencesProject;
