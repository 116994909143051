/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toIntlDateTimeFormat } from '@zedoc/date';
import Table from '../../../../common/components/Table';
import Text from '../../../../common/components/base/Text';
import Modal from '../../../../components/dialogs/Modal';

const getStatusColorCode = (status) => {
  switch (status) {
    case 'Failed':
      return 'danger';
    case 'Delivered':
      return 'success';
    default:
      return null;
  }
};

const DashboardNotificationModal = ({
  dataSource,
  title,
  visible,
  onCancel,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Text.Span type={getStatusColorCode(status)}>{status}</Text.Span>
      ),
    },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp) =>
        toIntlDateTimeFormat(language, new Date(timestamp), {
          dateStyle: 'short',
          timeStyle: 'short',
        }),
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
    },
  ];

  return (
    <Modal title={title} visible={visible} onOk={onCancel} onCancel={onCancel}>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </Modal>
  );
};

DashboardNotificationModal.propTypes = {};

DashboardNotificationModal.defaultProps = {};

export default DashboardNotificationModal;
