import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDDPCall, useDDPSubscription } from '@zedoc/ddp-connector';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import DashboardTable from './DashboardTable';
import {
  PROJECT_DASHBOARD_TYPE__EAPP,
  DASHBOARD_PERSPECTIVE__EAPPS,
  DASHBOARD_PERSPECTIVE__PATIENTS,
  DASHBOARD_PERSPECTIVE__RESPONSES,
  DASHBOARD_PERSPECTIVE__ACTIVITIES,
  DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
} from '../../../../common/constants';
import { default as UserSelect } from '../../../../common/selectors/User';
import { default as RecipientSelect } from '../../../../common/selectors/Recipient';
import createSelectors from '../../../../common/selectors/createSelectors';
import EAPP from '../../../../common/models/EAPP';
import usePagination from '../../../../utils/usePagination';
import cleanFilters from '../../../../utils/cleanFilters';
import {
  apiZedocSearchEAPPs,
  apiZedocUpdateEAPP,
} from '../../../../common/api/zedoc';
import { notifySuccess } from '../../../../utils/notify';
import { registerType } from '../../dashboards';
import Stack from '../../../../common/components/primitives/Stack';
import ConnectedFilters from './ConnectedFilters';
import store from '../../store';
// import DashboardNotesModal from './DashboardNotesModal';

const EAPPSelect = createSelectors(EAPP);

const DashboardEApp = ({
  projectId,
  answersSheetId,
  recipientId,
  participationId,
  activityId,
  dashboardId,
  hideColumns,
}) => {
  const { t } = useTranslation();
  const rawFilters = useSelector(
    store.get(`dashboards.${dashboardId}.filters`),
  );
  const filters = useMemo(
    () => cleanFilters(rawFilters, { anyOf: true }),
    [rawFilters],
  );

  const {
    // ready: subscriptionsReady,
    items: dataSource,
    getPaginationProps,
  } = usePagination({
    selector: EAPPSelect,
    mapSelector: (selector) => {
      return selector.lookup({
        from: RecipientSelect.all(),
        as: 'recipient',
        foreignKey: '_id',
        key: (doc, _, byRecipientId) => {
          return (
            byRecipientId[doc.recipientId] && byRecipientId[doc.recipientId][0]
          );
        },
      });
    },
    getSubscription: (currentPage, resultsPerPage) => {
      const params = {
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
        filters,
      };

      if (projectId) {
        params.projectId = projectId;
      }

      if (answersSheetId) {
        params.answersSheetId = answersSheetId;
      }

      if (recipientId) {
        params.recipientId = recipientId;
      }

      if (participationId) {
        params.participationId = participationId;
      }

      if (activityId) {
        params.activityId = activityId;
      }

      return apiZedocSearchEAPPs.withParams(params);
    },
  });

  // const dataSource = [
  //   {
  //     id: '0G68978798H',
  //     status: 'Resolved',
  //     notes: [
  //       {
  //         id: '1',
  //         title: 'Title',
  //         content: 'Content',
  //       },
  //     ],
  //     prp_notification: false,
  //     assigneeId: '1',
  //     priority: 'High',
  //     name: 'EQ5D low score alert',
  //     description:
  //       'If patient scores >8 in the scale question and an email will be triggered',
  //     createdAt: new Date(),
  //     recipientId: 'John Doe',
  //     actionType: 'Notification',
  //   },
  //   {
  //     id: '2G698bbuiHio',
  //     status: 'Open',
  //     notes: [
  //       {
  //         id: '1',
  //         title: 'Title',
  //         content: 'Content',
  //       },
  //       {
  //         id: '2',
  //         title: 'Title',
  //         content: 'Content',
  //       },
  //       {
  //         id: '3',
  //         title: 'Title',
  //         content: 'Content',
  //       },
  //     ],
  //     prp_notification: true,
  //     assigneeId: '2',
  //     priority: 'Medium',
  //     name: 'HADS - High Depression ',
  //     description:
  //       'In the HADS questionnaire if the patient scores a depression score between >8 an email will be triggered',
  //     createdAt: new Date(),
  //     recipientId: 'Alice Smith',
  //     actionType: 'Add Milestone',
  //   },
  // ];

  const { ddpCall, ddpIsPending } = useDDPCall();
  useDDPSubscription({
    name: 'Users.projectMembers',
    params: [projectId],
  });
  const projectMembers = useSelector(
    UserSelect.all().whenMemberOfProject(projectId),
  );
  const assignees = projectMembers.map(({ _id, name }) => ({
    value: _id,
    label: name,
  }));
  const handleOnChangeAssignee = async ({
    eappId,
    assignees: assigneesSelected,
  }) => {
    if (ddpIsPending) {
      return;
    }

    try {
      debounce(async () => {
        await ddpCall(
          apiZedocUpdateEAPP.withParams({
            eappId,
            assignees: assigneesSelected.map((assignee) => ({
              userId: assignee.value,
              name: assignee.label,
            })),
          }),
        );
        notifySuccess(t('forms:assignee.update'))();
      }, 1000)();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  return (
    <Stack>
      <ConnectedFilters
        storeKey={`dashboards.${dashboardId}`}
        projectId={projectId}
        recipientId={recipientId}
        participationId={participationId}
        activityId={activityId}
        answersSheetId={answersSheetId}
        dashboardId={dashboardId}
        hideColumns={hideColumns}
      />
      <DashboardTable
        dataSource={dataSource}
        assignees={assignees}
        onChangeAssignee={handleOnChangeAssignee}
        pagination={getPaginationProps()}
        hideColumns={hideColumns}
      />
    </Stack>
  );
};

DashboardEApp.propTypes = {
  projectId: PropTypes.string,
  answersSheetId: PropTypes.string,
  recipientId: PropTypes.string,
  participationId: PropTypes.string,
  activityId: PropTypes.string,
  dashboardId: PropTypes.string,
  hideColumns: PropTypes.arrayOf(PropTypes.string),
};

DashboardEApp.defaultProps = {
  projectId: null,
  answersSheetId: null,
  recipientId: null,
  participationId: null,
  activityId: null,
  dashboardId: null,
  hideColumns: null,
};

registerType(PROJECT_DASHBOARD_TYPE__EAPP, {
  component: DashboardEApp,
  perspectives: [
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__EAPPS,
      specializations: [
        DASHBOARD_PERSPECTIVE__PATIENTS,
        DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
        DASHBOARD_PERSPECTIVE__ACTIVITIES,
        DASHBOARD_PERSPECTIVE__RESPONSES,
      ],
    },
  ],
});

export default DashboardEApp;
