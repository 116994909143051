import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Participation from '../../../common/models/Participation';
import ConfirmAction from '../../dialogs/ConfirmAction';
import { notifySuccess } from '../../../utils/notify';
import NotificationPreferencesProject from './NotificationPreferencesProject';
import FormFieldSwitch from '../../forms/FormFieldSwitch';
import PatientStatus from './PatientStatus';

const PatientEngagementNotificationPreferences = ({
  participations,
  notificationMethodPreference,
  unsubscribe_all,
  subscribe_all,
  recipient,
  updateRecipientStatus,
}) => {
  const [enableNotifications, setEnableNotifications] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const handleOnOpenDialog = () => setIsDialogVisible(true);
  const handleOnCloseDialog = () => setIsDialogVisible(false);

  const handleOnSwitchEnableNotifications = () => {
    handleOnOpenDialog();
  };

  const handleOnEnableNotificationsDialogConfirm = () => {
    setIsLoading(true);

    if (!!recipient.unsubscribedAll) {
      subscribe_all();
      setIsLoading(false);
      handleOnCloseDialog();
      notifySuccess('Notifications disabled')();
    } else {
      unsubscribe_all();
      setIsLoading(false);
      handleOnCloseDialog();
      notifySuccess('Notifications enabled')();
    }
  };

  return (
    <>
      <div className="stack-6">
        <div className="grid grid-cols-3 gap-4">
          <div className="stack-6">
            <h2>Notification Preferences</h2>
            <FormFieldSwitch
              label="Enable Notifications for All Projects"
              input={{
                value: !recipient.unsubscribedAll,
                onChange: handleOnSwitchEnableNotifications,
              }}
            />
          </div>
          <div className="stack-6">
            <br />
            <PatientStatus
              recipient={recipient}
              updateRecipientStatus={updateRecipientStatus}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          {participations.map((participation) => (
            <NotificationPreferencesProject
              participation={participation}
              recipient={recipient}
              notificationMethodPreference={notificationMethodPreference}
              enableNotifications={
                !recipient.unsubscribed?.find(
                  (projectId) => projectId === participation.projectId,
                )
              }
            />
          ))}
        </div>
      </div>
      <ConfirmAction
        message={`Are you sure you want to ${
          !recipient.unsubscribedAll ? 'DISABLE' : 'ENABLE'
        } notifications for all projects?`}
        open={isDialogVisible}
        onConfirm={handleOnEnableNotificationsDialogConfirm}
        onCancel={handleOnCloseDialog}
        loading={isLoading}
      />
    </>
  );
};

PatientEngagementNotificationPreferences.propTypes = {
  participations: PropTypes.arrayOf(PropTypes.instanceOf(Participation)),
};

PatientEngagementNotificationPreferences.defaultProps = {
  participations: [],
};

export default PatientEngagementNotificationPreferences;
