import PropTypes from 'prop-types';
import { CheckBox, CheckBoxOutlineBlank } from 'styled-icons/material';
import { StickyNote } from 'styled-icons/fa-regular';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toIntlDateTimeFormat } from '@zedoc/date';
import { EAPP_STATE__RESOLVED } from '../../../../../common/schemata/EAPP';
import {
  EAPP_ACTION_TYPE__SEND_NOTIFICATION,
  EAPP_ACTION_TYPE__SEND_NOTIFICATION_TO_ASSIGNEE,
} from '../../../../../common/schemata/ProjectEAPPRule';
import Badge from '../../../../../common/components/Badge';
import Table from '../../../../../common/components/Table';
import Tag from '../../../../../common/components/Tag';
import Tooltip from '../../../../../common/components/Tooltip';
import Select from '../../../../../components/inputs/Select';
import Card from '../../../../../components/Card';
import Button from '../../../../../components/Button';
import branding from '../../../../../utils/branding';
import { openEAPPDialog } from '../../../actions';
import DashboardNotificationModal from '../DashboardNotificationModal';
import DashboardResolveModal from '../DashboardResolveModal';
import PatientColumn from './PatientColumn';
import { selectAllProjectMilestones, selectProject } from '../../../selectors';
import KeywordsDialog from './components/KeywordsDialog';
import { getPriorityType } from './helpers';
// import ScheduleVideoCall from './ScheduleVideoCall';

// NOTE: That should be a flag coming from "settings.public" or ENV var
const USE_KEYWORDS = true;

const DashboardTable = ({
  dataSource,
  pagination,
  loading,
  assignees: assigneesProp,
  onChangeAssignee,
  hideColumns,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();

  const [isNotificationModalVisible, setIsNotificationModalVisible] =
    useState(false);
  const [isResolveModalVisible, setIsResolveModalVisible] = useState(false);
  const [currentRecordId, setCurrentRecordId] = useState(null);

  const handleCloseNotificationModal = () => {
    setCurrentRecordId(null);
    setIsNotificationModalVisible(false);
  };
  const handleOpenNotesModal = (id) => {
    dispatch(
      openEAPPDialog({
        eappId: id,
      }),
    );
  };
  const handleOpenResolveModal = (id) => {
    setCurrentRecordId(id);
    setIsResolveModalVisible(true);
  };
  const handleCloseResolveModal = () => {
    setCurrentRecordId(null);
    setIsResolveModalVisible(false);
  };

  // const renderActionType = ({
  //   id,
  //   actionType,
  // }) => {
  //   const handleOpenNotificationModal = () => {
  //     setCurrentRecordId(id);
  //     setIsNotificationModalVisible(true);
  //   };

  //   switch (actionType) {
  //     case 'Notification':
  //       return (
  //         <ButtonLink onClick={handleOpenNotificationModal}>
  //           {actionType}
  //         </ButtonLink>
  //       );
  //     default:
  //       return (
  //         <Text.Span
  //           style={{
  //             whiteSpace: 'nowrap',
  //           }}
  //         >
  //           {actionType}
  //         </Text.Span>
  //       );
  //   }
  // };

  const getActionTypeTranslation = (type) => {
    let translationKey = `eapp:${type}`;

    if (type === EAPP_ACTION_TYPE__SEND_NOTIFICATION) {
      translationKey = 'notificationSent';
    }

    if (type === EAPP_ACTION_TYPE__SEND_NOTIFICATION_TO_ASSIGNEE) {
      return 'Notification to assignee';
    }

    return t(translationKey);
  };

  const projectMilestones = useSelector(selectAllProjectMilestones);
  const project = useSelector(selectProject);
  const projectVariables = project?.variables;
  const hhsNameVariable = projectVariables.find((x) => x.name === 'hhs_name');
  const hhsCodeVariable = projectVariables.find((x) => x.name === 'hhs_code');
  const facilityNameVariable = projectVariables.find(
    (x) => x.name === 'facility_name',
  );
  const urnVariable = projectVariables.find((x) =>
    ['urn', 'patient_urn'].includes(x.name),
  );

  const columns = [
    {
      title: t('date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) =>
        toIntlDateTimeFormat(language, createdAt, {
          dateStyle: 'short',
          timeStyle: 'short',
        }),
    },
    {
      title: t('referenceNo'),
      dataIndex: 'referenceNo',
      key: 'referenceNo',
    },
    {
      title: t('state'),
      dataIndex: 'state',
      key: 'state',
      render: (state, record) => (
        <Button
          type="ghost"
          icon={
            state === EAPP_STATE__RESOLVED ? (
              <CheckBox size="24" />
            ) : (
              <CheckBoxOutlineBlank size="24" />
            )
          }
          isIconSizeFixed={false}
          disabled={state === EAPP_STATE__RESOLVED}
          onClick={
            state !== EAPP_STATE__RESOLVED
              ? () => handleOpenResolveModal(record._id)
              : () => {}
          }
        />
      ),
    },
    {
      title: t('notes'),
      dataIndex: 'notes',
      key: 'notes',
      fixed: 'left',
      width: '55px',
      render: (notes, record) => (
        <Badge
          type="info"
          // NOTE: Eslint misinterprets this as a component function,
          //       i.e. first argument being props.
          // eslint-disable-next-line react/destructuring-assignment
          count={notes && notes.length}
          offsetX={-8}
          offsetY={8}
        >
          <Button
            type="ghost"
            icon={<StickyNote />}
            onClick={() => handleOpenNotesModal(record._id)}
          />
        </Badge>
      ),
    },
    {
      title: t('recipients', {
        count: 1,
        context: branding,
      }),
      dataIndex: 'recipientId',
      key: 'recipientId',
      width: 192,
      render: (recipientId, { projectId, recipient }) => (
        <PatientColumn recipient={recipient} projectId={projectId} />
      ),
    },
    hhsNameVariable
      ? {
          title: 'HHS Name',
          dataIndex: hhsNameVariable?.id,
          key: `patientVariables.${hhsNameVariable?.id}`,
          width: 192,
          render: (patientVariables, row) => (
            <span>{row.patientVariables[hhsNameVariable?.id]}</span>
          ),
        }
      : null,
    hhsCodeVariable
      ? {
          title: 'HHS Code',
          dataIndex: hhsCodeVariable?.id,
          key: `patientVariables.${hhsCodeVariable?.id}`,
          width: 192,
          render: (patientVariables, row) => (
            <span>{row.patientVariables[hhsCodeVariable?.id]}</span>
          ),
        }
      : null,
    facilityNameVariable
      ? {
          title: 'Facility Name',
          dataIndex: facilityNameVariable?.id,
          key: `patientVariables.${facilityNameVariable?.id}`,
          width: 192,
          render: (patientVariables, row) => (
            <span>{row.patientVariables[facilityNameVariable?.id]}</span>
          ),
        }
      : null,
    urnVariable
      ? {
          title: 'URN',
          dataIndex: urnVariable?.id,
          key: `patientVariables.${urnVariable?.id}`,
          width: 192,
          render: (patientVariables, row) => (
            <span>{row.patientVariables[urnVariable?.id]}</span>
          ),
        }
      : null,
    // {
    //   title: 'prp_notification',
    //   dataIndex: 'prp_notification',
    //   key: 'prp_notification',
    //   render: bool => (bool ? 'Yes' : 'No'),
    // },
    {
      title: 'Assignees',
      dataIndex: 'assignees',
      key: 'assignees',
      width: '350px',
      render: (assignees, record) =>
        record.state === EAPP_STATE__RESOLVED ? (
          assigneesProp
            .filter(({ value }) =>
              record.assignees?.find((x) => x.userId === value),
            )
            .map((o) => o.label)
            .join(', ')
        ) : (
          <Select
            size="default"
            options={assigneesProp}
            value={assignees?.map(({ userId }) => userId) || []}
            placeholder="No assignee"
            multiple
            onChange={(selected) => {
              onChangeAssignee({
                assignees: assigneesProp.filter(({ value }) =>
                  selected.includes(value),
                ),
                eappId: record._id,
              });
            }}
          />
        ),
    },
    {
      title: t('priority'),
      dataIndex: 'priority',
      key: 'priority',
      render: (priority) => (
        <Tag type={getPriorityType(priority)}>{t(priority)}</Tag>
      ),
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <Tooltip title={record.description}>{name}</Tooltip>
      ),
    },
    {
      title: t('Milestone'),
      dataIndex: 'milestoneId',
      key: 'milestoneId',
      render: (milestoneId) => {
        const record = projectMilestones.find(({ _id }) => _id === milestoneId);
        return <Tooltip title={record?.type}>{record?.name}</Tooltip>;
      },
    },
    {
      title: t('actionType'),
      dataIndex: 'actionsToTrigger',
      key: 'actionsToTrigger',
      width: '350px',
      // render: (_, record) => renderActionType(record),
      render: (actionsToTrigger) =>
        [
          ...new Set([
            ...actionsToTrigger.map(({ type }) =>
              getActionTypeTranslation(type),
            ),
          ]),
        ].join(', '),
    },
    // {
    //   title: t('videoCall'),
    //   dataIndex: 'videoCall',
    //   key: 'videoCall',
    //   render: (_, {
    //     recipient,
    //     assigneeId,
    //     recipientId,
    //   }) => (
    //     <ScheduleVideoCall
    //       identifier={recipient.getNationalId()}
    //       birthYear={recipient.getYearOfBirth()}
    //       name={recipient.getFullName()}
    //       assigneeId={assigneeId}
    //       recipientId={recipientId}
    //     />
    //   ),
    // },
  ].filter((x) => x);

  if (USE_KEYWORDS) {
    columns.push(
      {
        title: t('triggerKeywords'),
        dataIndex: 'keywords',
        key: 'keywords',
        render: (_, record) => {
          const [isOpen, setIsOpen] = useState(false);

          const keywords = useMemo(() => {
            const { keywordMatchRulesKeywords } = record;

            if (keywordMatchRulesKeywords) {
              return keywordMatchRulesKeywords
                .split(',')
                .map((keyword) => keyword.trim());
            }

            return [];
          }, [record]);

          return (
            <>
              <button
                type="button"
                className="text-primary underline hover:no-underline text-left"
                onClick={() => setIsOpen(true)}
              >
                {keywords.join(', ')}
              </button>
              <KeywordsDialog
                isOpen={isOpen}
                onCancel={() => setIsOpen(false)}
                referenceNo={record.referenceNo}
                recipientFullName={record.recipient.getFullName()}
                answersSheetId={record.answersSheetId}
                keywordMatchRulesQuestionId={record.keywordMatchRulesQuestionId}
                priority={record.priority}
                keywords={keywords}
                createdAt={record.createdAt}
              />
            </>
          );
        },
      },
      {
        title: t('forms:questionId.label'),
        dataIndex: 'keywordMatchRulesQuestionId',
        key: 'keywordMatchRulesQuestionId',
        render: (_, record) => {
          return record.keywordMatchRulesQuestionId;
        },
      },
    );
  }

  const filteredColumns = columns.filter((el) => {
    if (Array.isArray(hideColumns) && hideColumns.length > 0) {
      return !hideColumns.some(
        (item) => item.toLowerCase().trim() === el.title.toLowerCase().trim(),
      );
    }
    return true;
  });

  const notifications = [
    {
      id: '1',
      status: 'Delivered',
      timestamp: new Date(),
      destination: 'email@email.com',
    },
    {
      id: '2',
      status: 'Failed',
      timestamp: new Date(),
      destination: '+64123456789',
    },
  ];

  return (
    <>
      <DashboardResolveModal
        eappId={dataSource.find(({ _id }) => _id === currentRecordId)?._id}
        patientName={dataSource
          .find(({ _id }) => _id === currentRecordId)
          ?.recipient?.getFullName()}
        visible={isResolveModalVisible}
        onCancel={handleCloseResolveModal}
      />
      <DashboardNotificationModal
        title={dataSource.find(({ id }) => id === currentRecordId)?.recipientId}
        dataSource={notifications}
        visible={isNotificationModalVisible}
        onCancel={handleCloseNotificationModal}
      />
      <Card>
        <Table
          size="small"
          dataSource={dataSource}
          columns={filteredColumns}
          loading={loading}
          pagination={pagination}
          scroll={{
            x: true,
          }}
        />
      </Card>
    </>
  );
};

DashboardTable.propTypes = {
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  pagination: PropTypes.objectOf(PropTypes.any),
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  onChangeAssignee: PropTypes.func.isRequired,
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  dataSource: PropTypes.arrayOf(PropTypes.any),
};

DashboardTable.defaultProps = {
  loading: false,
  pagination: null,
  assignees: [],
  hideColumns: null,
  dataSource: [],
};

export default DashboardTable;
