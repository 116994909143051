import ApiSpec from './ApiSpec';
import {
  RESOURCE_TYPE__PROJECT,
  RESOURCE_TYPE__PATIENT,
  RESOURCE_TYPE__PARTICIPATION,
  PROJECT_SEARCH_PROJECT,
  PROJECT_FORCE_SYNCHRONIZATION,
  ORGANIZATION_ACCESS_VARIABLE,
  ORGANIZATION_SEARCH_QUESTIONNAIRE,
  ORGANIZATION_LOOKUP_EXTERNAL_PATIENTS_DATABASE,
  PROJECT_VIEW_ANALYTICS,
  PROJECT_ATTACH_PARTICIPATION,
  PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
  PROJECT_PROFILE_SEARCH_PARTICIPATION,
  PROJECT_REVIEW_PROJECT,
  PROJECT_PROFILE_REVIEW_PARTICIPATION,
  PROJECT_PROFILE_UPDATE_PARTICIPATION,
  PATIENT_MILESTONE_REVIEW_ACTIVITY,
  PATIENT_MILESTONE_SEND_ACTIVITY_NOTIFICATIONS,
  PATIENT_MILESTONE_OBTAIN_ACTIVITY_LINK,
  PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET,
  PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET,
  PATIENT_MILESTONE_UPDATE_ACTIVITY,
  PATIENT_MILESTONE_CREATE_ACTIVITY,
  PATIENT_MILESTONE_FORCE_SYNCHRONIZATION,
  PATIENT_MILESTONE_DELETE_ANSWERS_SHEET,
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
  PATIENT_REVIEW_PATIENT,
  PATIENT_SEARCH_PATIENT,
  PATIENT_ATTACH_PARTICIPATION,
  PROJECT_UPDATE_EAPP,
  PROJECT_REVIEW_EAPP,
  PROJECT_SEARCH_EAPP,
  PROJECT_PROFILE_ATTACH_ACTIVITY,
  PROJECT_PROFILE_DISCHARGE_PATIENT,
  PROJECT_CREATE_SURVEY_LINK,
  // PROJECT_PROFILE_SUBSCRIBE,
  // PATIENT_UPDATE_PATIENT,
} from '../permissions';
import {
  sorterSchema,
  searchApiSchema,
  searchFilterOptionsApiSchema,
  makeFilterSchema,
  mergeObjectSchemas,
} from './common';
import {
  ACTIVITY_STATES,
  PARTICIPATION_DISCHARGED_STATES,
  PATIENT_STATUS_ACTIVE,
  PATIENT_STATUS_INACTIVE,
  PATIENT_STATUS_DECEASED,
} from '../constants';
import Id from '../schemata/Id';
import IdentifierWithVersionRange from '../schemata/IdentifierWithVersionRange';
import IdentifierWithVersionNoAndI18n from '../schemata/IdentifierWithVersionNoAndI18n';
import NanoId from '../schemata/NanoId';
import FullDate from '../schemata/FullDate';
import { ACTION_TYPE__SEARCH, ACTION_TYPE__REVIEW } from '../messages';
import { type } from 'os';

export const apiZedocSearchPatientRecords = new ApiSpec({
  name: 'api.zedoc.searchPatientRecords',
  actionType: ACTION_TYPE__SEARCH,
  resources: [
    PROJECT_REVIEW_PROJECT,
    {
      variables: {
        viewParticipations: PROJECT_PROFILE_SEARCH_PARTICIPATION,
        viewPii: PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      },
    },
  ],
  schema: {
    type: 'object',
    required: ['projectId'],
    definitions: {
      filter: makeFilterSchema('#/definitions/filter'),
    },
    properties: {
      projectId: Id,
      filters: {
        type: 'array',
        items: { $ref: '#/definitions/filter' },
      },
      sorter: sorterSchema,
      pageIndex: {
        type: 'number',
        minimum: 0,
      }, // 0, 1, 2, 3, etc.
      resultsPerPage: {
        type: 'number',
        minimum: 0,
      },
      controlId: {
        type: 'string',
      },
    },
  },
});

export const apiZedocSearchPatientRecordsFilterOptions = new ApiSpec({
  name: 'api.zedoc.searchPatientRecords.filterOptions',
  resources: [
    PROJECT_REVIEW_PROJECT,
    {
      variables: {
        viewParticipations: PROJECT_PROFILE_SEARCH_PARTICIPATION,
        viewPii: PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      },
    },
  ],
  schema: mergeObjectSchemas(searchFilterOptionsApiSchema, {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: Id,
    },
  }),
});

export const apiZedocSearchRecipients = new ApiSpec({
  name: 'api.zedoc.searchRecipients',
  actionType: ACTION_TYPE__SEARCH,
  resources: [
    {
      variables: {
        viewRecipients: PATIENT_SEARCH_PATIENT,
        viewParticipations: PROJECT_PROFILE_REVIEW_PARTICIPATION,
        viewPii: PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      },
    },
  ],
  schema: searchApiSchema,
});

export const apiZedocSearchRecipientsFilterOptions = new ApiSpec({
  name: 'api.zedoc.searchRecipients.filterOptions',
  resources: [
    {
      variables: {
        viewRecipients: PATIENT_SEARCH_PATIENT,
        viewPii: PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      },
    },
  ],
  schema: searchFilterOptionsApiSchema,
});

export const apiZedocExternalPatientSearch = new ApiSpec({
  name: 'api.zedoc.externalPatientSearch',
  resources: [
    {
      variables: {
        accessPatientPii: PATIENT_ACCESS_PATIENT_PII_VARIABLES,
        lookupExternalPatientsDatabase:
          ORGANIZATION_LOOKUP_EXTERNAL_PATIENTS_DATABASE,
      },
    },
  ],
  schema: {
    type: 'object',
    required: ['apiEndpoint'],
    properties: {
      apiEndpoint: {
        type: 'string',
      },
      searchParameters: {
        type: 'object',
        additionalProperties: {
          type: 'string',
        },
      },
      resultId: {
        type: 'string',
      },
    },
  },
});

export const patientDetails = new ApiSpec({
  name: 'api.zedoc.patientDetails',
  actionType: ACTION_TYPE__REVIEW,
  resources: [
    PATIENT_REVIEW_PATIENT,
    {
      variables: {
        viewParticipations: PROJECT_PROFILE_REVIEW_PARTICIPATION,
      },
    },
  ],
  schema: {
    type: 'object',
    required: ['recipientId'],
    properties: {
      projectId: Id,
      recipientId: Id,
    },
  },
});

export const patientActivityDetails = new ApiSpec({
  name: 'api.zedoc.patientActivityDetails',
  actionType: ACTION_TYPE__REVIEW,
  resources: [
    PATIENT_MILESTONE_REVIEW_ACTIVITY,
    {
      type: RESOURCE_TYPE__PATIENT,
    },
  ],
  schema: {
    type: 'object',
    required: ['activityId'],
    properties: {
      activityId: Id,
    },
  },
});

export const apiZedocPatientActivityNotifications = new ApiSpec({
  name: 'api.zedoc.patientActivityNotifications',
  permissions: [PATIENT_MILESTONE_REVIEW_ACTIVITY],
  schema: {
    type: 'object',
    required: ['activityId'],
    properties: {
      activityId: Id,
    },
  },
});

export const apiZedocAllProjects = new ApiSpec({
  name: 'api.zedoc.allProjects',
  permissions: [PROJECT_SEARCH_PROJECT],
  schema: {
    type: 'object',
    additionalProperties: false,
  },
});

export const apiZedocMyProjects = new ApiSpec({
  name: 'api.zedoc.myProjects',
  permissions: [PROJECT_SEARCH_PROJECT],
  schema: {
    type: 'object',
    additionalProperties: false,
  },
});

export const apiZedocOneProject = new ApiSpec({
  name: 'api.zedoc.oneProject',
  resources: [PROJECT_REVIEW_PROJECT],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: Id,
    },
  },
});

export const apiZedocIdentifierVariables = new ApiSpec({
  name: 'api.zedoc.identifierVariables',
  resources: [
    {
      variables: {
        scope: ORGANIZATION_ACCESS_VARIABLE,
      },
    },
  ],
  schema: {
    type: 'object',
    additionalProperties: false,
  },
});

export const apiZedocPatientVariables = new ApiSpec({
  name: 'api.zedoc.patientVariables',
  resources: [
    {
      variables: {
        scope: ORGANIZATION_ACCESS_VARIABLE,
      },
    },
  ],
  schema: {
    type: 'object',
    additionalProperties: false,
  },
});

export const apiZedocProjectVariables = new ApiSpec({
  name: 'api.zedoc.projectVariables',
  resources: [PROJECT_REVIEW_PROJECT],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: Id,
    },
  },
});

export const apiZedocProjectVariablesSummary = new ApiSpec({
  name: 'api.zedoc.projectVariablesSummary',
  resources: [
    {
      variables: {
        viewProjects: PROJECT_SEARCH_PROJECT,
      },
    },
  ],
  schema: {
    type: 'object',
    additionalProperties: false,
  },
});

export const projectMilestones = new ApiSpec({
  name: 'api.zedoc.projectMilestones',
  permissions: [PROJECT_REVIEW_PROJECT],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: {
        type: 'string',
      },
    },
  },
});

export const generateStudyNo = new ApiSpec({
  name: 'api.zedoc.generateStudyNo',
  permissions: [PROJECT_ATTACH_PARTICIPATION],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: Id,
    },
  },
});

export const sendActivityReminder = new ApiSpec({
  name: 'api.zedoc.sendActivityReminder',
  permissions: [PATIENT_MILESTONE_SEND_ACTIVITY_NOTIFICATIONS],
  schema: {
    type: 'object',
    required: ['activityId'],
    properties: {
      activityId: Id,
    },
  },
});

export const apiZedocRemoveEmptyAnswersSheet = new ApiSpec({
  name: 'api.zedoc.removeEmptyAnswersSheet',
  resources: [PATIENT_MILESTONE_DELETE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      answersSheetId: Id,
    },
  },
});

export const changeActivity = new ApiSpec({
  name: 'api.zedoc.changeActivity',
  permissions: [PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId', 'activityId'],
    properties: {
      answersSheetId: Id,
      activityId: Id,
    },
  },
});

export const answersSheetDetails = new ApiSpec({
  name: 'api.zedoc.answersSheetDetails',
  permissions: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      answersSheetId: Id,
    },
  },
});

export const apiZedocGetPDFForAnswersSheet = new ApiSpec({
  name: 'api.zedoc.getPDFForAnswersSheet',
  permissions: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      answersSheetId: Id,
      audience: {
        type: 'string',
      },
      language: {
        type: 'string',
      },
    },
  },
});

export const apiZedocCopyActivity = new ApiSpec({
  name: 'api.zedoc.copyActivity',
  resources: [
    PATIENT_MILESTONE_UPDATE_ACTIVITY,
    PATIENT_MILESTONE_CREATE_ACTIVITY,
  ],
  schema: {
    type: 'object',
    required: ['activityId'],
    properties: {
      activityId: {
        type: 'string',
      },
      state: {
        type: 'string',
        enum: ACTIVITY_STATES,
      },
      dateStart: {
        type: 'string',
        format: 'date',
      },
      dateEnd: {
        type: 'string',
        format: 'date',
      },
      timeStart: {
        type: 'string',
        format: 'time',
      },
      timeEnd: {
        type: 'string',
        format: 'time',
      },
      keepOriginal: {
        type: 'boolean',
      },
      keepOriginalResponses: {
        type: 'boolean',
      },
    },
  },
});

export const apiZedocProjectSummary = new ApiSpec({
  name: 'api.zedoc.projectSummary',
  resources: [
    {
      // note that there's no action here, only permission check
      can: PROJECT_REVIEW_PROJECT,
    },
    {
      variables: {
        viewEAPPs: PROJECT_REVIEW_EAPP,
        viewActivities: PATIENT_REVIEW_PATIENT,
        viewParticipations: PROJECT_PROFILE_REVIEW_PARTICIPATION,
      },
    },
  ],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: Id,
    },
  },
});

export const apiZedocPatientServiceSyncProgress = new ApiSpec({
  name: 'api.zedoc.patientServiceSyncProgress',
  resources: [
    {
      // note that there's no action here, only permission check
      can: PROJECT_REVIEW_PROJECT,
    },
  ],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: Id,
    },
  },
});

export const generateLoginLinkForPtA = new ApiSpec({
  name: 'api.zedoc.generateLoginLinkForPtA',
  resources: [
    PATIENT_MILESTONE_OBTAIN_ACTIVITY_LINK,
    {
      type: RESOURCE_TYPE__PROJECT,
    },
    {
      type: RESOURCE_TYPE__PATIENT,
    },
    {
      type: RESOURCE_TYPE__PARTICIPATION,
    },
  ],
  schema: {
    type: 'object',
    required: ['activityId'],
    properties: {
      activityId: {
        type: 'string',
      },
    },
  },
});

export const forceSyncToPatientService = new ApiSpec({
  name: 'api.zedoc.forceSyncToPatientService',
  permissions: [PROJECT_FORCE_SYNCHRONIZATION],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: {
        type: 'string',
      },
    },
  },
});

export const apiZedocSyncActivity = new ApiSpec({
  name: 'api.zedoc.apiZedocSyncActivity',
  permissions: [PATIENT_MILESTONE_FORCE_SYNCHRONIZATION],
  schema: {
    type: 'object',
    required: ['activityId'],
    properties: {
      activityId: {
        type: 'string',
      },
    },
  },
});

export const apiZedocQuestionnairesRepositoryMetadata = new ApiSpec({
  name: 'api.zedoc.questionnairesRepositoryMetadata',
  permissions: [ORGANIZATION_SEARCH_QUESTIONNAIRE],
  schema: {
    type: 'object',
    additionalProperties: false,
  },
});

export const apiZedocSearchQuestionnaireRecords = new ApiSpec({
  name: 'api.zedoc.searchQuestionnaireRecords',
  permissions: [ORGANIZATION_SEARCH_QUESTIONNAIRE],
  schema: searchApiSchema,
});

export const apiZedocSearchQuestionnaireRecordsFilterOptions = new ApiSpec({
  name: 'api.zedoc.searchQuestionnaireRecords.filterOptions',
  permissions: [ORGANIZATION_SEARCH_QUESTIONNAIRE],
  schema: searchFilterOptionsApiSchema,
});

export const apiZedocSearchQuestionnaireTags = new ApiSpec({
  name: 'api.zedoc.searchQuestionnaireTags',
  permissions: [ORGANIZATION_SEARCH_QUESTIONNAIRE],
  schema: {
    type: 'object',
    properties: {
      resultId: { type: 'string' },
    },
  },
});

export const apiZedocSearchParticipationAndQuestionnaire = new ApiSpec({
  name: 'api.zedoc.searchParticipationAndQuestionnaire',
  resources: [
    PROJECT_VIEW_ANALYTICS,
    {
      variables: {
        viewParticipations: PROJECT_PROFILE_SEARCH_PARTICIPATION,
        viewPii: PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      },
    },
  ],
  schema: mergeObjectSchemas(searchApiSchema, {
    type: 'object',
    required: ['projectId', 'questionnaireId'],
    properties: {
      projectId: Id,
      questionnaireId: {
        type: 'string',
      },
    },
  }),
});

export const apiZedocSearchParticipationAndQuestionnaireFilterOptions =
  new ApiSpec({
    name: 'api.zedoc.searchParticipationAndQuestionnaire.filterOptions',
    resources: [
      PROJECT_VIEW_ANALYTICS,
      {
        variables: {
          viewParticipations: PROJECT_PROFILE_SEARCH_PARTICIPATION,
          viewPii: PATIENT_ACCESS_PATIENT_PII_VARIABLES,
        },
      },
    ],
    schema: mergeObjectSchemas(searchFilterOptionsApiSchema, {
      type: 'object',
      required: ['projectId', 'questionnaireId'],
      properties: {
        projectId: {
          type: 'string',
        },
        questionnaireId: {
          type: 'string',
        },
      },
    }),
  });

export const apiZedocSearchProjects = new ApiSpec({
  name: 'api.zedoc.searchProjects',
  resources: [
    {
      variables: {
        viewProjects: PROJECT_REVIEW_PROJECT,
        viewParticipations: PROJECT_PROFILE_REVIEW_PARTICIPATION,
      },
    },
  ],
  schema: searchApiSchema,
});

export const apiZedocSearchResponses = new ApiSpec({
  name: 'api.zedoc.searchResponses',
  resources: [
    {
      variables: {
        scope: PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
      },
    },
  ],
  schema: mergeObjectSchemas(searchApiSchema, {
    type: 'object',
    properties: {
      projectId: Id,
      recipientId: Id,
      questionnaireId: {
        type: 'string',
      },
    },
  }),
});

export const apiZedocSearchEAPPs = new ApiSpec({
  name: 'api.zedoc.searchEAPPs',
  resources: [
    {
      variables: {
        scope: PROJECT_SEARCH_EAPP,
      },
    },
  ],
  schema: mergeObjectSchemas(searchApiSchema, {
    type: 'object',
    properties: {
      projectId: Id,
      recipientId: Id,
      participationId: Id,
      activityId: Id,
      answersSheetId: Id,
    },
  }),
});

export const apiZedocSearchEAPPsFilterOptions = new ApiSpec({
  name: 'api.zedoc.searchEAPPs.filterOptions',
  resources: [
    {
      variables: {
        scope: PROJECT_SEARCH_EAPP,
      },
    },
  ],
  schema: mergeObjectSchemas(searchFilterOptionsApiSchema, {
    type: 'object',
    properties: {
      projectId: Id,
      recipientId: Id,
      participationId: Id,
      activityId: Id,
      answersSheetId: Id,
    },
  }),
});

// ZEDOC EAPP API SPEC

export const apiZedocUpdateEAPP = new ApiSpec({
  name: 'api.zedoc.updateEAPP',
  resources: [
    PROJECT_UPDATE_EAPP,
    {
      type: RESOURCE_TYPE__PROJECT,
    },
  ],
  schema: {
    type: 'object',
    required: ['eappId'],
    properties: {
      eappId: Id,
      assigneeId: Id,
      completeStep: {
        type: 'boolean',
      },
      noteContent: {
        type: 'string',
      },
      assignees: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            userId: Id,
            name: {
              type: 'string',
            },
          },
        },
      },
    },
  },
});

export const apiZedocCreateParticipation = new ApiSpec({
  name: 'api.zedoc.createParticipation',
  resources: [
    PROJECT_ATTACH_PARTICIPATION,
    {
      if: 'recipientId',
      then: [PATIENT_ATTACH_PARTICIPATION],
      // NOTE: Otherwise, permissions are validated by integration worker at a different level.
      else: [],
    },
  ],
  schema: {
    type: 'object',
    required: ['projectId'],
    additionalProperties: false,
    properties: {
      projectId: {
        type: 'string',
      },
      recipientId: {
        type: 'string',
      },
      variables: {
        type: 'object',
        additionalProperties: true,
      },
      proceedEvenIfIdentifierExists: {
        type: 'boolean',
      },
      correlationId: {
        type: 'string',
      },
    },
  },
});

export const apiZedocUpdateParticipation = new ApiSpec({
  name: 'api.zedoc.updateParticipation',
  resources: [PROJECT_PROFILE_UPDATE_PARTICIPATION],
  schema: {
    type: 'object',
    required: ['participationId'],
    additionalProperties: false,
    properties: {
      participationId: {
        type: 'string',
      },
      variables: {
        type: 'object',
        additionalProperties: true,
      },
      correlationId: {
        type: 'string',
      },
    },
  },
});

export const apiZedocCreateActivity = new ApiSpec({
  name: 'api.zedoc.createActivity',
  resources: [PROJECT_PROFILE_ATTACH_ACTIVITY],
  schema: {
    type: 'object',
    required: ['participationId'],
    additionalProperties: false,
    properties: {
      participationId: {
        type: 'string',
      },
      variables: {
        type: 'object',
        additionalProperties: true,
      },
      correlationId: {
        type: 'string',
      },
    },
  },
});

export const apiZedocUpdateActivity = new ApiSpec({
  name: 'api.zedoc.updateActivity',
  resources: [PATIENT_MILESTONE_UPDATE_ACTIVITY],
  schema: {
    type: 'object',
    required: ['activityId'],
    additionalProperties: false,
    properties: {
      activityId: {
        type: 'string',
      },
      variables: {
        type: 'object',
        additionalProperties: true,
      },
      correlationId: {
        type: 'string',
      },
    },
  },
});

export const apiZedocDischargeRecipient = new ApiSpec({
  name: 'api.zedoc.dischargeRecipient',
  resources: [PROJECT_PROFILE_DISCHARGE_PATIENT],
  schema: {
    type: 'object',
    required: ['participationId', 'state', 'comment'],
    additionalProperties: false,
    properties: {
      participationId: {
        type: 'string',
      },
      state: {
        type: 'string',
        enum: PARTICIPATION_DISCHARGED_STATES,
      },
      comment: {
        type: 'string',
      },
    },
  },
});

export const apiZedocSubscribeRecipient = new ApiSpec({
  name: 'api.zedoc.subscribeRecipient',
  resources: [
    {
      if: 'recipientId',
      then: [PATIENT_REVIEW_PATIENT],
      // NOTE: Otherwise, permissions are validated by integration worker at a different level.
      else: [],
    },
    {
      if: 'projectId',
      then: [PROJECT_REVIEW_PROJECT],
      else: [],
    },
  ],
  schema: {
    type: 'object',
    required: ['recipientId', 'projectId'],
    additionalProperties: false,
    properties: {
      recipientId: {
        type: 'string',
      },
      projectId: {
        type: 'string',
      },
    },
  },
});

export const apiZedocSubscribeRecipientAllProjects = new ApiSpec({
  name: 'api.zedoc.subscribeRecipientAllProjects',
  resources: [
    {
      if: 'recipientId',
      then: [PATIENT_REVIEW_PATIENT],
      // NOTE: Otherwise, permissions are validated by integration worker at a different level.
      else: [],
    },
  ],
  schema: {
    type: 'object',
    required: ['recipientId'],
    additionalProperties: false,
    properties: {
      recipientId: {
        type: 'string',
      },
    },
  },
});
export const apiZedocUnsubscribeRecipientAllProjects = new ApiSpec({
  name: 'api.zedoc.unSubscribeRecipientAllProjects',
  resources: [
    {
      if: 'recipientId',
      then: [PATIENT_REVIEW_PATIENT],
      // NOTE: Otherwise, permissions are validated by integration worker at a different level.
      else: [],
    },
  ],
  schema: {
    type: 'object',
    required: ['recipientId'],
    additionalProperties: false,
    properties: {
      recipientId: {
        type: 'string',
      },
    },
  },
});

export const apiZedocNotificationMethodPreferenceRecipientPerProjects =
  new ApiSpec({
    name: 'api.zedoc.apiZedocNotificationMethodPreferenceRecipientPerProjects',
    resources: [
      {
        if: 'recipientId',
        then: [PATIENT_REVIEW_PATIENT],
        // NOTE: Otherwise, permissions are validated by integration worker at a different level.
        else: [],
      },
    ],
    schema: {
      type: 'object',
      required: ['recipientId'],
      additionalProperties: false,
      properties: {
        recipientId: {
          type: 'string',
        },
        projectId: {
          type: 'string',
        },
        notificationMethod: {
          type: 'string',
        },
      },
    },
  });

export const apiZedocUpdateRecipientNotificationsInfo = new ApiSpec({
  name: 'api.zedoc.apiZedocUpdateRecipientNotificationsInfo',
  resources: [
    {
      if: 'recipientId',
      then: [PATIENT_REVIEW_PATIENT],
      // NOTE: Otherwise, permissions are validated by integration worker at a different level.
      else: [],
    },
  ],
  schema: {
    type: 'object',
    required: ['recipientId'],
    additionalProperties: false,
    properties: {
      recipientId: {
        type: 'string',
      },
      email: {
        type: 'string',
      },
      phone: {
        type: 'string',
      },
    },
  },
});

export const apiZedocUpdateRecipientStatus = new ApiSpec({
  name: 'api.zedoc.apiZedocUpdateRecipientStatus',
  resources: [
    {
      if: 'recipientId',
      then: [PATIENT_REVIEW_PATIENT],
      // NOTE: Otherwise, permissions are validated by integration worker at a different level.
      else: [],
    },
  ],
  schema: {
    type: 'object',
    required: ['recipientId'],
    additionalProperties: false,
    properties: {
      recipientId: {
        type: 'string',
      },
      status: {
        type: 'string',
        enum: [
          PATIENT_STATUS_ACTIVE,
          PATIENT_STATUS_INACTIVE,
          PATIENT_STATUS_DECEASED,
        ],
      },
    },
  },
});

export const apiZedocSendSignUpLink = new ApiSpec({
  name: 'api.zedoc.apiZedocSendSignUpLink',
  resources: [
    {
      if: 'recipientId',
      then: [PATIENT_REVIEW_PATIENT],
      // NOTE: Otherwise, permissions are validated by integration worker at a different level.
      else: [],
    },
  ],
  schema: {
    type: 'object',
    required: ['recipientId'],
    additionalProperties: false,
    properties: {
      recipientId: {
        type: 'string',
      },
      type: {
        type: 'string',
      },
    },
  },
});

export const apiZedocSendResetPepLink = new ApiSpec({
  name: 'api.zedoc.apiZedocSendResetPepLink',
  resources: [
    {
      if: 'recipientId',
      then: [PATIENT_REVIEW_PATIENT],
      // NOTE: Otherwise, permissions are validated by integration worker at a different level.
      else: [],
    },
  ],
  schema: {
    type: 'object',
    required: ['recipientId'],
    additionalProperties: false,
    properties: {
      recipientId: {
        type: 'string',
      },
    },
  },
});

export const apiZedocDeletePEPUser = new ApiSpec({
  name: 'api.zedoc.apiZedocDeletePEPUser',
  resources: [
    {
      if: 'recipientId',
      then: [PATIENT_REVIEW_PATIENT],
      // NOTE: Otherwise, permissions are validated by integration worker at a different level.
      else: [],
    },
  ],
  schema: {
    type: 'object',
    required: ['recipientId'],
    additionalProperties: false,
    properties: {
      recipientId: {
        type: 'string',
      },
    },
  },
});

export const apiZedocQuestionnaire = new ApiSpec({
  name: 'api.zedoc.questionnaire',
  resources: [
    {
      if: 'projectId',
      then: [PROJECT_REVIEW_PROJECT],
      else: [],
    },
  ],
  schema: {
    type: 'object',
    required: ['questionnaireId'],
    properties: {
      questionnaireId: IdentifierWithVersionRange,
      projectId: {
        type: 'string',
      },
    },
  },
});

export const apiZedocQuestionnaireForAnswersSheet = new ApiSpec({
  name: 'api.zedoc.questionnaireTranslationForAnswersSheet',
  resources: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      translationId: IdentifierWithVersionNoAndI18n,
      answersSheetId: Id,
    },
  },
});

export const apiZedocListTranslationOptionsForAnswersSheet = new ApiSpec({
  name: 'api.zedoc.listTranslationOptionsForAnswersSheet',
  resources: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      answersSheetId: Id,
      resultId: {
        type: 'string',
      },
    },
  },
});

export const apiZedocProjectQuestionnaires = new ApiSpec({
  name: 'api.zedoc.projectQuestionnaires',
  resources: [PROJECT_REVIEW_PROJECT],
  schema: {
    type: 'object',
    properties: {
      projectId: {
        type: 'string',
      },
    },
  },
});

export const apiZedocGetPresetGuestToken = new ApiSpec({
  name: 'api.zedoc.getPresetGuestToken',
  resources: [
    PROJECT_VIEW_ANALYTICS,
    {
      if: 'recipientId',
      then: [PATIENT_REVIEW_PATIENT],
      else: [],
    },
    {
      if: 'participationId',
      then: [PROJECT_PROFILE_REVIEW_PARTICIPATION],
      else: [],
    },
    {
      variables: {
        searchParticipation: PROJECT_PROFILE_SEARCH_PARTICIPATION,
      },
    },
  ],
  schema: {
    type: 'object',
    required: ['projectId', 'embeddedDashboardId'],
    properties: {
      projectId: {
        type: 'string',
      },
      recipientId: {
        type: 'string',
      },
      participationId: {
        type: 'string',
      },
      embeddedDashboardId: {
        type: 'string',
      },
      useOverlays: {
        type: 'boolean',
      },
    },
  },
});

export const apiZedocGetSupersetGuestToken = new ApiSpec({
  name: 'api.zedoc.getSupersetGuestToken',
  resources: [
    PROJECT_VIEW_ANALYTICS,
    {
      if: 'recipientId',
      then: [PATIENT_REVIEW_PATIENT],
      else: [],
    },
    {
      if: 'participationId',
      then: [PROJECT_PROFILE_REVIEW_PARTICIPATION],
      else: [],
    },
    {
      variables: {
        searchParticipation: PROJECT_PROFILE_SEARCH_PARTICIPATION,
      },
    },
  ],
  schema: {
    type: 'object',
    required: ['projectId', 'embeddedDashboardId'],
    properties: {
      projectId: {
        type: 'string',
      },
      recipientId: {
        type: 'string',
      },
      participationId: {
        type: 'string',
      },
      embeddedDashboardId: {
        type: 'string',
      },
      useOverlays: {
        type: 'boolean',
      },
      disableOwnershipFilter: {
        type: 'boolean',
      },
    },
  },
});

export const apiZedocCreateSurveyLink = new ApiSpec({
  name: 'api.zedoc.createSurveyLink',
  resources: [PROJECT_CREATE_SURVEY_LINK],
  schema: {
    type: 'object',
    // NOTE: "id" is optional and it can be used to guarantee idempotency of the operation
    required: ['projectId', 'trackId', 'milestoneId', 'dateEnd'],
    properties: {
      id: NanoId,
      projectId: Id,
      trackId: Id,
      milestoneId: Id,
      dateStart: FullDate,
      dateEnd: FullDate,
      name: {
        type: 'string',
      },
      inactive: {
        type: 'boolean',
      },
      expiresIn: {
        type: 'number',
      },
    },
  },
});

export const apiZedocUpdateSurveyLink = new ApiSpec({
  name: 'api.zedoc.updateSurveyLink',
  resources: [PROJECT_CREATE_SURVEY_LINK],
  schema: {
    type: 'object',
    required: ['projectId', 'id'],
    properties: {
      id: NanoId,
      projectId: Id,
      inactive: {
        type: 'boolean',
      },
      expiresIn: {
        type: ['number', 'null'],
      },
    },
  },
});

export const apiZedocSupersetGetMailReportsByUserAndDashboardWise = new ApiSpec(
  {
    name: 'api.zedoc.supersetGetMailReportsByUserAndDashboardWise',
    resources: [],
    schema: {
      type: 'object',
      required: ['embeddedDashboardId'],
      properties: {
        embeddedDashboardId: {
          type: 'string',
        },
      },
    },
  },
);

export const apiZedocSupersetGetMailReportLogsByUserAndDashboardWise =
  new ApiSpec({
    name: 'api.zedoc.supersetGetMailReportLogsByUserAndDashboardWise',
    resources: [],
    schema: {
      type: 'object',
      required: ['embeddedDashboardId', 'reportId'],
      properties: {
        embeddedDashboardId: {
          type: 'string',
        },
        reportId: {
          type: 'number',
        },
      },
    },
  });

export const apiZedocSupersetGetSingleMailReportByReportIdUserAndDashboardWise =
  new ApiSpec({
    name: 'api.zedoc.supersetGetSingleMailReportByReportIdUserAndDashboardWise',
    resources: [],
    schema: {
      type: 'object',
      required: ['embeddedDashboardId', 'reportId'],
      properties: {
        embeddedDashboardId: {
          type: 'string',
        },
        reportId: {
          type: 'number',
        },
      },
    },
  });

export const apiZedocSupersetDeleteMailReportByReportIdUserAndDashboardWise =
  new ApiSpec({
    name: 'api.zedoc.supersetDeleteMailReportByReportIdUserAndDashboardWise',
    resources: [],
    schema: {
      type: 'object',
      required: ['embeddedDashboardId'],
      properties: {
        embeddedDashboardId: {
          type: 'string',
        },
        reportId: {
          type: 'number',
        },
      },
    },
  });

export const apiZedocSupersetSaveMailReportByUserAndDashboardWise = new ApiSpec(
  {
    name: 'api.zedoc.supersetSaveMailReportByUserAndDashboardWise',
    schema: {
      type: 'object',
      required: ['embeddedDashboardId', 'report'],
      properties: {
        embeddedDashboardId: {
          type: 'string',
        },
        report: {
          type: 'object',
          required: ['active', 'name', 'crontab', 'recipient'],
          properties: {
            reportId: {
              type: ['number'],
            },
            active: {
              type: 'boolean',
            },
            name: {
              type: 'string',
            },
            description: {
              type: 'string',
            },
            crontab: {
              type: 'string',
            },
            timezone: {
              type: 'string',
            },
            recipient: {
              type: 'object',
              properties: {
                target: {
                  type: 'string',
                },
                ccTarget: {
                  type: 'string',
                },
                bccTarget: {
                  type: 'string',
                },
              },
            },
          },
        },
      },
    },
  },
);
