import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Field,
  FieldArray,
  reduxForm,
  submit,
  getFormValues,
} from 'redux-form';
import Button from '../../../../../components/Button';
import Dialog from '../../../../../components/Dialog';
import FormFieldText from '../../../../../components/forms/FormFieldText';
import FormFieldSearch from '../../../../../components/forms/FormFieldSearch';
import FormFieldSelect from '../../../../../components/forms/FormFieldSelect';
import { makeJsonSchemaFormValidator } from '../../../../../utils/errors';
import {
  daysOfMonthOptions,
  daysOfWeekOptions,
  hoursOptions,
  INTERVAL_DAY,
  INTERVAL_HOUR,
  INTERVAL_MONTH,
  INTERVAL_WEEK,
  INTERVAL_YEAR,
  intervalOptions,
  minutesOptions,
  monthsOptions,
  cronToParams,
  paramsToCron,
} from './helpers';
import {
  callMethod,
  // ...
} from '../../../../../common/utilsClient/ddp/actions';
import {
  apiZedocSupersetGetMailReportsByUserAndDashboardWise,
  apiZedocSupersetSaveMailReportByUserAndDashboardWise,
  apiZedocSupersetGetSingleMailReportByReportIdUserAndDashboardWise,
  apiZedocSupersetDeleteMailReportByReportIdUserAndDashboardWise,
  apiZedocSupersetGetMailReportLogsByUserAndDashboardWise,
} from '../../../../../common/api/zedoc';
import { notifyError } from '../../../../../utils/notify';

// ======================
// Helper Components
// ======================

const form = 'screens_projects_dashboard_manageEmailReports';

const EmailReportProperties = ({ member }) => {
  const { t } = useTranslation();
  const formValues = useSelector((state) => getFormValues(form)(state));
  const idx = member.match(/\[(\d+)\]/)[1];
  const { interval } = formValues.emailReports[idx];

  return (
    <div className="stack-6">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Field
          data-testid="field-name"
          component={FormFieldText}
          name={`${member}.name`}
          type="text"
          label={t('forms:name.label')}
          required
        />
        <div
          style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
        >
          <label style={{ marginRight: '8px' }}>{t('Active')}</label>
          <Field name={`${member}.active`} component="input" type="checkbox" />
        </div>
      </div>
      <Field
        data-testid="field-description"
        component={FormFieldText}
        name={`${member}.description`}
        type="freeText"
        label={t('forms:description.label')}
        required
      />
      <Field
        data-testid="field-interval"
        component={FormFieldSelect}
        name={`${member}.interval`}
        label="Every"
        options={intervalOptions}
        required
      />
      {/* Show or hide fields depending on selected interval */}
      {interval === INTERVAL_YEAR && (
        <Field
          data-testid="field-months"
          component={FormFieldSearch}
          name={`${member}.months`}
          label="Months"
          options={monthsOptions}
          mode="multiple"
          showSearch
          required
        />
      )}
      {(interval === INTERVAL_YEAR || interval === INTERVAL_MONTH) && (
        <Field
          data-testid="field-days-of-month"
          component={FormFieldSearch}
          name={`${member}.daysOfMonth`}
          label="Days of Month"
          options={daysOfMonthOptions}
          mode="multiple"
          showSearch
          required
        />
      )}
      {(interval === INTERVAL_YEAR ||
        interval === INTERVAL_MONTH ||
        interval === INTERVAL_WEEK) && (
        <Field
          data-testid="field-days-of-week"
          component={FormFieldSearch}
          name={`${member}.daysOfWeek`}
          label="Days of Week"
          options={daysOfWeekOptions}
          mode="multiple"
          showSearch
          required
        />
      )}
      {(interval === INTERVAL_YEAR ||
        interval === INTERVAL_MONTH ||
        interval === INTERVAL_WEEK ||
        interval === INTERVAL_DAY) && (
        <Field
          data-testid="field-hours"
          component={FormFieldSearch}
          name={`${member}.hours`}
          label="Hours"
          options={hoursOptions}
          mode="multiple"
          showSearch
          required
        />
      )}
      {(interval === INTERVAL_YEAR ||
        interval === INTERVAL_MONTH ||
        interval === INTERVAL_WEEK ||
        interval === INTERVAL_DAY ||
        interval === INTERVAL_HOUR) && (
        <Field
          data-testid="field-minutes"
          component={FormFieldSearch}
          name={`${member}.minutes`}
          label="Minutes"
          options={minutesOptions}
          mode="multiple"
          showSearch
          required
        />
      )}
      <Field
        data-testid="field-timezone"
        component={FormFieldSearch}
        valueSetId="zedoc/timezones@1.0.0"
        name={`${member}.timezone`}
        label={t('forms:timezone.label')}
        required
      />
      <Field
        data-testid="field-emails"
        component={FormFieldSearch}
        name={`${member}.recipient.target`}
        label="Email Recipients"
        mode="tags"
      />
      <Field
        data-testid="field-cc-emails"
        component={FormFieldSearch}
        name={`${member}.recipient.ccTarget`}
        label="Email CC Recipients"
        mode="tags"
      />
      <Field
        data-testid="field-bcc-emails"
        component={FormFieldSearch}
        name={`${member}.recipient.bccTarget`}
        label="Email BCC Recipients"
        mode="tags"
      />
    </div>
  );
};

EmailReportProperties.propTypes = {
  member: PropTypes.string.isRequired,
};

const EmailReportsList = ({ fields, embeddedDashboardId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formValues = useSelector((state) => getFormValues(form)(state));
  const [expandedIndex, setExpandedIndex] = useState(null);

  // State for the "Logs" dialog
  const [logsOpen, setLogsOpen] = useState(false);
  const [logsData, setLogsData] = useState({});

  // Add new email-report row
  const handleAdd = () => {
    fields.push({ active: true });
    setExpandedIndex(fields.length); // newly added item index
  };

  const handleCancelItem = (index) => {
    const itemValues = formValues?.emailReports?.[index];
    if (!itemValues?.reportId) {
      fields.remove(index);
    }
    setExpandedIndex(null);
  };

  // Show logs
  const showLogs = (index) => {
    const itemValues = formValues?.emailReports?.[index];
    if (!itemValues?.reportId) {
      console.warn('No values to edit for item at index', index);
      return;
    }

    dispatch(
      callMethod(apiZedocSupersetGetMailReportLogsByUserAndDashboardWise, {
        embeddedDashboardId,
        reportId: itemValues.reportId,
      }),
    )
      .then((data) => {
        const logDatas = {
          logs: data || [],
          name: itemValues.name,
          crontab_humanized: itemValues.crontab_humanized,
        };
        setLogsData(logDatas || { logs: [] });
        setLogsOpen(true);
      })
      .catch(notifyError());
  };

  // Close logs
  const closeLogs = () => {
    setLogsData([]);
    setLogsOpen(false);
  };

  // Edit existing item
  const handleEdit = (index) => {
    const itemValues = formValues?.emailReports?.[index];
    if (!itemValues?.reportId) {
      console.warn('No values to edit for item at index', index);
      return;
    }

    dispatch(
      callMethod(
        apiZedocSupersetGetSingleMailReportByReportIdUserAndDashboardWise,
        {
          embeddedDashboardId,
          reportId: itemValues.reportId,
        },
      ),
    )
      .then((data) => {
        formValues.emailReports[index] = {
          ...data,
          ...cronToParams(data?.crontab),
        };
        setExpandedIndex(index);
      })
      .catch(notifyError());
  };

  const handleRemove = (index) => {
    const itemValues = formValues?.emailReports?.[index];
    if (!itemValues?.reportId) {
      console.warn('No values to remove for item at index', index);
      return;
    }

    const confirmed = window.confirm(
      'Are you sure you want to DELETE this report?',
    );
    if (!confirmed) {
      return;
    }

    dispatch(
      callMethod(
        apiZedocSupersetDeleteMailReportByReportIdUserAndDashboardWise,
        {
          embeddedDashboardId,
          reportId: itemValues.reportId,
        },
      ),
    )
      .then((data) => {
        if (data == true) {
          fields.remove(index);
          if (expandedIndex === index) setExpandedIndex(null);
        }
      })
      .catch(notifyError());
  };

  // Save an individual item.
  const handleSaveItem = (index) => {
    const itemValues = formValues?.emailReports?.[index];
    if (!itemValues) {
      console.warn('No values to save for item at index', index);
      return;
    }

    const params = {
      embeddedDashboardId,
      report: {
        ...(itemValues.reportId && { reportId: itemValues.reportId }),
        active: itemValues.active,
        name: itemValues.name,
        description: itemValues.description,
        crontab: paramsToCron({
          interval: itemValues.interval,
          minutes: itemValues.minutes,
          hours: itemValues.hours,
          daysOfMonth: itemValues.daysOfMonth,
          months: itemValues.months,
          daysOfWeek: itemValues.daysOfWeek,
        }),
        timezone: itemValues.timezone,
        recipient: {
          target: itemValues?.recipient?.target?.join(';') || '',
          ccTarget: itemValues?.recipient?.ccTarget?.join(';') || '',
          bccTarget: itemValues?.recipient?.bccTarget?.join(';') || '',
        },
      },
    };
    dispatch(
      callMethod(apiZedocSupersetSaveMailReportByUserAndDashboardWise, params),
    )
      .then((data) => {
        formValues.emailReports[index] = {
          ...data,
          ...cronToParams(data?.crontab),
        };

        console.log('SAVE', formValues.emailReports[index]);
        setExpandedIndex(null);
      })
      .catch(notifyError());
  };

  return (
    <div>
      <p>Total: {fields.length}</p>
      {fields.map((member, index) => {
        const isExpanded = expandedIndex === index;
        return (
          <div
            key={member}
            style={{
              border: '1px solid #ccc',
              margin: '8px 0',
              padding: '8px',
            }}
          >
            {/* Title / Buttons row */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '6px',
              }}
            >
              <span style={{ fontWeight: 'bold', marginRight: '8px' }}>
                {index + 1}
              </span>

              {!isExpanded && (
                <button
                  type="button"
                  onClick={() => handleEdit(index)}
                  style={{
                    background: 'green',
                    color: '#fff',
                    marginRight: '8px',
                  }}
                >
                  Edit
                </button>
              )}

              {isExpanded && (
                <button
                  type="button"
                  style={{
                    background: 'green',
                    color: '#fff',
                    marginRight: '8px',
                  }}
                  onClick={() => handleSaveItem(index)}
                >
                  Save
                </button>
              )}

              {formValues?.emailReports?.[index]?.reportId && (
                <button
                  type="button"
                  onClick={() => handleRemove(index)}
                  style={{
                    background: 'red',
                    color: '#fff',
                    marginRight: '8px',
                  }}
                >
                  Remove
                </button>
              )}

              {isExpanded && (
                <button
                  type="button"
                  style={{
                    background: 'orange',
                    color: '#fff',
                    marginRight: '8px',
                  }}
                  onClick={() => handleCancelItem(index)}
                >
                  Cancel
                </button>
              )}

              {formValues?.emailReports?.[index]?.reportId && (
                <button
                  type="button"
                  style={{
                    background: 'blue',
                    color: '#fff',
                    marginRight: '8px',
                  }}
                  onClick={() => showLogs(index)}
                >
                  Logs
                </button>
              )}
            </div>

            {!isExpanded && (
              <div style={{ marginTop: '4px' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <strong>
                      {formValues?.emailReports?.[index]?.name || 'No Name'}
                    </strong>
                  </div>
                  <div
                    style={{
                      textAlign: 'right',
                      color: formValues?.emailReports?.[index]?.active
                        ? 'green'
                        : 'red',
                    }}
                  >
                    {formValues?.emailReports?.[index]?.active
                      ? 'Active'
                      : 'Inactive'}
                  </div>
                </div>
              </div>
            )}

            {isExpanded && <EmailReportProperties member={member} />}
          </div>
        );
      })}

      <Button onClick={handleAdd}>Add</Button>

      {/* ====================================== */}
      {/* Logs Dialog (conditionally displayed) */}
      <Dialog
        title={logsData.name}
        visible={logsOpen}
        onCancel={closeLogs}
        style={{
          width: '80vw',
          height: '80vh',
          overflow: 'auto',
        }}
      >
        <div style={{ padding: '1rem' }}>
          {logsData.crontab_humanized}

          {logsData.logs && logsData.logs.length > 0 ? (
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: '1px solid #ccc',
                      textAlign: 'left',
                    }}
                  >
                    State
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid #ccc',
                      textAlign: 'left',
                    }}
                  >
                    Scheduled
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid #ccc',
                      textAlign: 'left',
                    }}
                  >
                    Error
                  </th>
                </tr>
              </thead>
              <tbody>
                {logsData.logs.map((log) => (
                  <tr key={log.id}>
                    <td style={{ borderBottom: '1px solid #ccc' }}>
                      {log.state}
                    </td>
                    <td style={{ borderBottom: '1px solid #ccc' }}>
                      {log.scheduled_dttm}
                    </td>
                    <td style={{ borderBottom: '1px solid #ccc' }}>
                      {log.error_message || '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No log data found.</p>
          )}
        </div>
      </Dialog>
    </div>
  );
};

EmailReportsList.propTypes = {
  fields: PropTypes.object.isRequired,
};

const ManageEmailReportsForm = reduxForm({
  validate: makeJsonSchemaFormValidator({
    type: 'object',
    required: ['emailReports'],
    properties: {
      emailReports: {
        type: 'array',
        items: {
          type: 'object',
          required: [
            'name',
            'description',
            'timezone',
            'interval',
            'months',
            'daysOfMonth',
            'daysOfWeek',
            'hours',
            'minutes',
          ],
          properties: {
            name: {
              type: 'string',
              title: 'Name',
            },
            description: {
              type: 'string',
              title: 'Description',
            },
            timezone: {
              type: 'string',
              title: 'Timezone',
            },
            interval: {
              type: 'string',
              title: 'Every',
            },
            months: {
              type: 'array',
              title: 'Months',
            },
            daysOfMonth: {
              type: 'array',
              title: 'Days of Month',
            },
            daysOfWeek: {
              type: 'array',
              title: 'Days of Week',
            },
            hours: {
              type: 'array',
              title: 'Hours',
            },
            minutes: {
              type: 'array',
              title: 'Minutes',
            },
            emails: {
              type: 'array',
              title: 'Email Recipients',
            },
            ccEmails: {
              type: 'array',
              title: 'Email CC Recipients',
            },
            bccEmails: {
              type: 'array',
              title: 'Email BCC Recipients',
            },
          },
        },
        minItems: 1,
      },
      embeddedDashboardId: {
        type: 'string',
      },
    },
  }),
  form, // use the constant defined above
})(({ error, embeddedDashboardId }) => {
  const { t } = useTranslation();

  return (
    <div>
      {error && <span>{error}</span>}
      <FieldArray
        name="emailReports"
        label={t('emailReports')}
        component={EmailReportsList}
        embeddedDashboardId={embeddedDashboardId}
      />
    </div>
  );
});

const ManageEmailReports = ({ embeddedDashboardId }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const handleOnOpenDialog = () => {
    dispatch(
      callMethod(apiZedocSupersetGetMailReportsByUserAndDashboardWise, {
        embeddedDashboardId,
      }),
    )
      .then((data) => {
        setEmailReports(data);
        setIsVisible(true);
      })
      .catch(notifyError());
  };
  const handleOnCloseDialog = () => setIsVisible(false);

  // TODO: Replace "emailReports" with collection items coming from backend
  const [emailReports, setEmailReports] = useState([]);
  const initialValues = {
    emailReports: emailReports.length ? emailReports : [],
  };
  const dispatch = useDispatch();
  const doSubmit = () => dispatch(submit(form));
  const onSubmit = (data) => {
    // TODO: Add backend
  };

  return (
    <>
      <Button onClick={handleOnOpenDialog}>{t('manageEmailReports')}</Button>
      <Dialog
        title={t('manageEmailReports')}
        visible={isVisible}
        onCancel={handleOnCloseDialog}
      >
        <ManageEmailReportsForm
          form={form}
          initialValues={initialValues}
          embeddedDashboardId={embeddedDashboardId}
        />
      </Dialog>
    </>
  );
};

export default ManageEmailReports;
