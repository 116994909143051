import Id from './Id';
import Ownership from './Ownership';
import VersionNo from './VersionNo';
import VersionRange from './VersionRange';
import {
  PATIENT_AUTH_FALLBACK_TYPES,
  ANSWERS_SHEET_NAVIGATION_TYPES,
} from '../constants';
import WorkerMetadata from './WorkerMetadata';
import NanoId from './NanoId';

export const PROJECT_STATE__INITIAL = 'INITIAL';
export const PROJECT_STATE__CONFIGURED = 'CONFIGURED';
export const PROJECT_STATE__ACTIVE = 'ACTIVE';
// TODO: Consider adding this state in the future.
// export const PROJECT_STATE__SUSPENDED = 'SUSPENDED';

export const PROJECT_STATES = [
  PROJECT_STATE__INITIAL,
  PROJECT_STATE__CONFIGURED,
  PROJECT_STATE__ACTIVE,
];

export const PROJECT_ACTION__CONFIGURE = 'configure';
export const PROJECT_ACTION__RECONFIGURE = 'reconfigure';
export const PROJECT_ACTION__START = 'start';

export const PROJECT_ACTIONS = [
  PROJECT_ACTION__CONFIGURE,
  PROJECT_ACTION__RECONFIGURE,
  PROJECT_ACTION__START,
];

export const PROJECT_STATE_MACHINE = {
  modelName: 'Project',
  coordinates: [
    {
      name: 'state',
      type: 'string',
    },
    {
      // NOTE: It is one of the state variables because we want to trace
      //       all changes to this property. Changing timezone can have potentially
      //       destructive effects, e.g. on notifications scheduling.
      name: 'timezone',
      type: 'string',
    },
    {
      name: 'blueprintId',
      type: 'string',
      pattern: Id.pattern,
    },
    {
      name: 'blueprintVersion',
      type: 'string',
      pattern: VersionNo.pattern,
    },
    {
      name: 'blueprintInconsistent',
      type: 'boolean',
    },
  ],
  states: [
    {
      state: PROJECT_STATE__INITIAL,
    },
    {
      state: PROJECT_STATE__CONFIGURED,
    },
    {
      state: PROJECT_STATE__ACTIVE,
    },
  ],
  transitions: [
    // INITIAL -> CONFIGURED (configure)
    // INITIAL -> ACTIVE (start)
    {
      from: PROJECT_STATE__INITIAL,
      to: PROJECT_STATE__CONFIGURED,
      actionType: PROJECT_ACTION__CONFIGURE,
      payload: ['timezone', 'blueprintId', 'blueprintVersion'],
    },
    {
      from: PROJECT_STATE__INITIAL,
      to: PROJECT_STATE__ACTIVE,
      actionType: PROJECT_ACTION__START,
      payload: ['timezone', 'blueprintId', 'blueprintVersion'],
    },
    // CONFIGURED -> CONFIGURED (reconfigure)
    // CONFIGURED -> ACTIVE (start)
    {
      from: PROJECT_STATE__CONFIGURED,
      to: PROJECT_STATE__CONFIGURED,
      actionType: PROJECT_ACTION__RECONFIGURE,
      payload: ['timezone', 'blueprintVersion'],
    },
    {
      from: PROJECT_STATE__CONFIGURED,
      to: PROJECT_STATE__ACTIVE,
      actionType: PROJECT_ACTION__START,
      payload: ['timezone', 'blueprintVersion'],
    },
    // ACTIVE -> ACTIVE (reconfigure)
    {
      from: PROJECT_STATE__ACTIVE,
      to: PROJECT_STATE__ACTIVE,
      actionType: PROJECT_ACTION__RECONFIGURE,
      payload: ['timezone', 'blueprintVersion', 'blueprintInconsistent'],
    },
  ],
};

const QuestionnaireVariableBinding = {
  type: 'object',
  required: ['questionnaireVariableId', 'variableId'],
  properties: {
    questionnaireVariableId: {
      type: 'string',
    },
    variableId: {
      type: 'string',
    },
  },
};

const QuestionnaireComputation = {
  type: 'object',
  required: ['name'],
  properties: {
    name: {
      type: 'string',
    },
    questionnaireVariableId: {
      type: 'string',
    },
    expression: {
      type: 'string',
    },
  },
};

const TemplateVariableBinding = {
  type: 'object',
  required: ['templateVariableId', 'variableId'],
  properties: {
    templateVariableId: {
      type: 'string',
    },
    variableId: {
      type: 'string',
    },
  },
};

const Questionnaire = {
  type: 'object',
  required: ['version', 'identifier'],
  properties: {
    version: {
      type: 'string',
    },
    identifier: {
      type: 'string',
    },
    // To be used with dashboard formulas
    namespace: {
      type: 'string',
    },
    navigationTypes: {
      type: 'array',
      items: {
        type: 'string',
        enum: ANSWERS_SHEET_NAVIGATION_TYPES,
      },
    },
    finalScoreQuestionId: {
      type: 'string',
    },
    // Specify how questionnaire variables should be initialized based on system variables.
    initialBindings: {
      type: 'array',
      items: QuestionnaireVariableBinding,
    },
    // Specify how system variables should be updated after questionnaire completion.
    finalBindings: {
      type: 'array',
      items: QuestionnaireVariableBinding,
    },
    finalComputations: {
      type: 'array',
      items: QuestionnaireComputation,
    },
  },
};

const CSVSchemata = {
  type: 'object',
  required: ['id', 'name'],
  properties: {
    id: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    columns: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          header: {
            type: 'string',
          },
          bindingType: {
            type: 'string',
          },
          variableId: {
            type: 'string',
          },
          isUniqueIdentifier: {
            type: 'boolean',
          },
          uniqueIdentifierScope: {
            type: 'string',
          },
          uniqueIdentifierScopeName: {
            type: 'string',
          },
        },
      },
    },
  },
};

const Project = {
  type: 'object',
  required: ['ownership', 'name'],
  properties: {
    // state
    // timezone
    // blueprintId
    // blueprintVersion
    blueprintVersionRange: VersionRange,
    ownership: Ownership,
    archived: {
      type: 'boolean',
    },
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    fallbackLanguage: {
      type: 'string',
    },
    otherSupportedLanguages: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    logoUrl: {
      type: 'string',
    },
    maxParticipants: {
      type: 'number',
    },
    billingCode: {
      type: 'string',
    },
    questionnaires: {
      type: 'array',
      items: Questionnaire,
    },
    // Variables that will be used to create project participant profile.
    variables: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id'],
        properties: {
          id: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          compulsory: {
            // if this flag is set, variable will be compulsory if used
            // in an in-app form; however, it does not guarantee that the value
            // will always be present, e.g. if patient was injected into the
            // system through different means
            type: 'boolean',
          },
        },
      },
    },
    csvSchemata: {
      type: 'array',
      items: CSVSchemata,
    },
    messageTemplateBindings: {
      type: 'array',
      items: TemplateVariableBinding,
    },
    idMapping: {
      type: 'object',
    },
    requirePatientAuth: {
      type: 'boolean',
    },
    editableAba: {
      type: 'boolean',
    },
    whenAuthNotPossible: {
      type: 'string',
      enum: PATIENT_AUTH_FALLBACK_TYPES,
    },
    allowUseOfSurveyLinks: {
      type: 'boolean',
    },
    allowEmailCollection: {
      type: 'boolean',
    },
    surveyLinks: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id', 'url', 'createdBy', 'createdAt'],
        properties: {
          id: NanoId,
          url: { type: 'string' },
          name: { type: 'string' },
          jwtPayload: { type: 'object', additionalProperties: true },
          inactive: { type: 'boolean' },
          createdBy: Id,
          createdAt: { bsonType: 'date' },
          // NOTE: Number of seconds for sessionJwt expiry. This token will be generated by patient-backend.
          expiresIn: { type: 'number' },
        },
      },
    },
  },
  patternProperties: {
    '^_worker:': WorkerMetadata,
  },
};

export default Project;
